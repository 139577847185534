/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
//THIS FILE IS A COPY OF \CentralWebTeam\packages\helios\src\browserSupport.js KEEP IN SYNC
// es5 code - ensure this is always the case!
//This file is executed BEFORE any of the react code is called. This means anything configured on app start up, such as i18n cannot be used here.
import { detect } from "detect-browser";

var supportedBrowsers = [
  { name: "chrome", minVersion: 102 },
  { name: "firefox", minVersion: 101 },
  { name: "edge-chromium", minVersion: 102 },
  //opera 88 is based on chromium 102 if a customer request ever comes in to add it.
];

function isBrowserSupported(browser) {
  if (browser) {
    var name = browser.name;
    var version = browser.version;
    var supported = false;
    for (var i = 0; i < supportedBrowsers.length; i++) {
      var supportedBrowser = supportedBrowsers[i];
      if (
        supportedBrowser.name === name &&
        version &&
        parseInt(version) >= supportedBrowser.minVersion
      ) {
        supported = true;
        break;
      }
    }
    return supported;
  }
  // if it failed to detect then let's just try to run it anyway!
  return true;
}

var browser = detect();
export var isSupported = isBrowserSupported(browser);
