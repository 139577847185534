/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  Machine,
  MachineStatus,
  FileDetailsForSelectedJob,
} from "@centralwebteam/narwhal";
import { NotificationType } from "@centralwebteam/jellyfish";
import { PopupUpdate, JobSummaryView } from "@/store/state";
import { LocationUI } from "@/presentation/Location";
import { Config } from "@/index";

const setMachines = createStandardAction("[global] fetched machines")<
  Machine[]
>();

const setMachineStatusUpdates = createStandardAction(
  "[global] machine status updates fetched"
)<MachineStatus[]>();

const setLocations = createStandardAction("[global] fetched locations")<
  LocationUI[]
>();

const setMachineTypes = createStandardAction("[global] fetched machine types")<
  string[]
>();

const setNotification = createStandardAction("[global] set notification")<{
  body: string[];
  header: string;
  show: boolean;
  id: string;
  type: NotificationType;
}>();

const showDialogue = createStandardAction("[global] set dialogue")<{
  message: string;
  confirm: () => void;
  show: boolean;
}>();

const popupUpdated = createStandardAction(
  "[global] popup updated"
)<PopupUpdate>();

const popupGroupCleared = createStandardAction(
  "[global] popup group cleared"
)<string>();

const refreshCoreData = createStandardAction("[global] refresh core data")();

const clearData = createStandardAction("[global] clear data")<void>();

const toggleLocationNode = createStandardAction(
  "[global] location node toggled"
)<[string, boolean?]>();

const machineToggled = createStandardAction(
  "[global] machine toggled"
)<string>();

const collapseAllLocations = createStandardAction(
  "[global] all locations collapsed"
)<void>();

const collapseAllMachines = createStandardAction(
  "[global] collapse all machines"
)<void>();

const expandAllLocations = createStandardAction(
  "[global] all locations expanded"
)<void>();

const expandAllMachines = createStandardAction(
  "[global] expand all machines"
)<void>();

const jobSummaryViewChanged = createStandardAction(
  "[global] job summary view changed"
)<JobSummaryView>();

const performanceNotificationRaised = createStandardAction(
  "[global] performance notification raised"
)();

const currentStatusHeaderLinkClicked = createStandardAction(
  "[global] current status header link clicked"
)();
const jobPerformanceHeaderLinkClicked = createStandardAction(
  "[global] job performance header link clicked"
)();
const machineAnalysisHeaderLinkClicked = createStandardAction(
  "[global] machine analysis header link clicked"
)();
const machinePerformanceHeaderLinkClicked = createStandardAction(
  "[global]machine performance header link clicked"
)();
const processUpdatesHeaderLinkClicked = createStandardAction(
  "[global] process updates header link clicked"
)();
const manageCogHeaderLinkClicked = createStandardAction(
  "[global] manage cog icon header link clicked"
)();
const infoHeaderLinkEscapeKeyPressed = createStandardAction(
  "[global] manage about icon header link escape key"
)();

const licenceServiceRefreshButtonClicked = createStandardAction(
  "[global] licence service refresh button clicked"
)();

const setFilesDetailsForSelectedJob = createStandardAction(
  "[global] set files details for the selcted file Ids"
)<FileDetailsForSelectedJob[]>();

const jobSummaryPopupFilesTabClicked = createStandardAction(
  "[global] fetch files details for selected file Info Ids"
)<{ fileInfoIds: string[] }>();

const jobSummaryPopupDownloadFileButtonClicked = createStandardAction(
  "[global] download file for selected file Id"
)<{
  fileId: string;
  fileName: string;
  downloadFilename: string;
  fileExtension: string;
  extension: string;
}>();

const setDownloadFileState = createStandardAction(
  "[global] set state of file for selected file Id"
)<"initial" | "downloading" | "success" | "fail">();

const configLoaded = createStandardAction("[global] config loaded")<Config>();

const liveRefreshModeStateChanged = createStandardAction(
  "[global] live refresh button toggled"
)<{ liveRefreshModeState: boolean }>();

export const actions = {
  refreshCoreData,
  clearData,
  popupUpdated,
  popupGroupCleared,
  setLocations,
  setMachines,
  setMachineStatusUpdates,
  setMachineTypes,
  setNotification,
  showDialogue,
  toggleLocationNode,
  expandAllLocations,
  collapseAllLocations,
  machineToggled,
  collapseAllMachines,
  expandAllMachines,
  jobSummaryViewChanged,
  performanceNotificationRaised,
  currentStatusHeaderLinkClicked,
  jobPerformanceHeaderLinkClicked,
  machineAnalysisHeaderLinkClicked,
  machinePerformanceHeaderLinkClicked,
  processUpdatesHeaderLinkClicked,
  manageCogHeaderLinkClicked,
  infoHeaderLinkEscapeKeyPressed,
  configLoaded,
  licenceServiceRefreshButtonClicked,
  setFilesDetailsForSelectedJob,
  jobSummaryPopupFilesTabClicked,
  jobSummaryPopupDownloadFileButtonClicked,
  setDownloadFileState,
  liveRefreshModeStateChanged,
};

export type Actions = ActionType<typeof actions>;
