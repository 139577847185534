/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  OffsetAdjustmentApplied,
  MasteringUpdate,
  OffsetChangeDetected,
  OffsetAdjustmentCalculation,
  ProcessActionDetails,
  instanceOfMasteringUpdate,
} from "@centralwebteam/narwhal";
import {
  AlertLevel,
  levelMapper,
  levelColours,
} from "@/presentation/Alert/level";
import constants from "@/constants";
import { JobPresentation } from "../Job";

const getLevelFromDetails = (details: ProcessActionDetails | undefined) => {
  const wearPercentage = details?.offsetLifeCycle?.wearPercentage;
  if (wearPercentage === undefined) {
    return AlertLevel.offset;
  }

  if (wearPercentage > constants.offsetErrorMin) {
    return AlertLevel.offsetError;
  }
  if (
    wearPercentage >= constants.offsetWarningMin &&
    wearPercentage <= constants.offsetWarningMax
  ) {
    return AlertLevel.offsetWarning;
  }
  return AlertLevel.offset;
};

/** Selects the ProcessActionPresentation from `processActions` to display for the MeasurementInfo `measurement` and JobPresentation `job` */
export const selectDisplayProcessAction = (
  processActions: ProcessActionPresentation[],
  measurement: { name?: string; featureName?: string },
  jobs: JobPresentation[]
) => {
  if (!measurement || !processActions?.length) return undefined;

  const matchingActions = processActions.filter(
    (pa) =>
      pa.featureName === measurement.featureName &&
      pa.measurementCharacteristicName === measurement.name &&
      jobs.some((j) => j.id === pa.jobId)
  );

  // Single or undefined ProcessAction
  if (matchingActions.length < 2) return matchingActions.at(0);

  // Return highest priority action
  return (
    matchingActions.find((pa) => pa.level === AlertLevel.offsetError) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetWarning) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offset) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetOther) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetInfo) ||
    matchingActions.find((pa) => pa.level === AlertLevel.offsetOtherInfo) ||
    matchingActions.at(0)
  );
};

function getProcessUpdateAlertLevel(
  subType: string,
  details: ProcessActionDetails | undefined
): AlertLevel {
  if (subType === "OffsetChangeDetected") {
    return AlertLevel.offsetOtherInfo;
  } else if (subType === "OffsetAdjustmentApplied") {
    return details?.executionSettings?.updateMode === "Set"
      ? AlertLevel.offsetOther
      : getLevelFromDetails(details);
  }
  return details?.executionSettings?.updateMode === "Set"
    ? AlertLevel.offsetOtherInfo
    : AlertLevel.offsetInfo;
}

export class ProcessActionPresentation {
  id: string;
  name: string;
  level: AlertLevel;
  created: Date;
  fill: string;
  layer?: number | undefined;
  subType: string;
  updateMode: string | undefined;
  jobId: string | undefined;
  featureName?: string | undefined;
  jobName?: string | undefined;
  measurementCharacteristicName?: string | undefined;
  masterJobName?: string | undefined;

  constructor(
    event:
      | OffsetAdjustmentApplied
      | OffsetChangeDetected
      | OffsetAdjustmentCalculation
      | MasteringUpdate
  ) {
    const { subType, id, name, created } = event;

    this.subType = subType;
    this.name = name;
    this.id = id;
    this.created = new Date(created);

    if (
      subType === "OffsetAdjustmentApplied" ||
      subType === "OffsetChangeDetected" ||
      subType === "OffsetAdjustmentCalculation"
    ) {
      const details = event.details;

      this.jobName = details?.analysisTrigger?.name;
      this.jobId = details?.analysisTrigger?.id;
      this.featureName = details?.processTrigger?.characteristic.featureName;
      this.measurementCharacteristicName =
        details?.processTrigger?.characteristic.name;
      this.updateMode = details?.executionSettings?.updateMode;
      this.level = getProcessUpdateAlertLevel(this.subType, details);
    } else {
      // We're expecting a MasteringUpdate here, though it could also be an unknown type
      if (instanceOfMasteringUpdate(event)) {
        this.jobName = event.masterJobName;
        this.masterJobName = event.masterJobName;
      }
      this.level = levelMapper(subType);
    }

    this.fill = levelColours[this.level];
  }
}
