/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getContext, call, put, takeLatest, delay } from "typed-redux-saga";
import { getType } from "typesafe-actions";
import { jwtDecode } from "jwt-decode";
import i18n from "@/i18n";
import constants from "@/constants";
import { rootActions, PayloadAction } from "@/store";
import { getCoreDataSaga } from "@/store/global/sagas";
import { dismissNotification, setNotification } from "@/store/global/thunks";
import {
  AuthResponse,
  AuthSuccess,
  isAuthFailure,
  SystemPermissions,
  System,
} from "@centralwebteam/narwhal";
import { removeTenancyFromEmailAddress } from "@centralwebteam/jellyfish";
import { history } from "@/store/syncStoreAndLocation";
import { getErrorMessage } from "@/axios/errors";
import { CMSClient, CMSClientType } from "@/cms-api/";
import { getGlobalConfigs } from "@/index";

// Allow snake_case, as used in the API calls
/* eslint-disable camelcase */

function* getUserNameFromAuth(authObject: AuthSuccess) {
  const client = yield* getContext<CMSClientType>("client");
  const { access_token } = authObject;
  const { sub: subjectId } = jwtDecode(access_token);

  if (subjectId) {
    const userData = yield* call(() => client.users.byId(subjectId!).promise);
    if (userData?.email) return userData.email;
  }
  return "";
}

export const getUMRT = () => {
  return new URLSearchParams(history.location.search || location.search).get(
    "umrt"
  );
};

/**
 * Attaches watchers for user authentication actions.
 */
export function* userAuthenticationWatcherSaga() {
  yield* takeLatest(getType(rootActions.session.userLoginSubmitted), logInSaga);
  yield* takeLatest(
    getType(rootActions.session.userSessionRefreshed),
    userSessionRefreshedSaga
  );
  yield* takeLatest(
    getType(rootActions.session.userRequestedPasswordResetLink),
    resetPasswordSendLinkSaga
  );
  yield* takeLatest(
    getType(rootActions.session.userRequestedPasswordReset),
    resetPasswordUpdateSaga
  );
}

function* logInWithAuthObject(authObject: AuthSuccess & { username: string }) {
  if (!authObject) return;
  const client = yield* getContext<CMSClientType>("client");
  const { username, access_token } = authObject;

  const { sub: subjectId } = jwtDecode(access_token);
  if (!subjectId) {
    clearAuthentication();
    return;
  }
  const permissions = yield* call(getUserPermissions, subjectId);
  if (permissions === undefined) {
    clearAuthentication();
  } else {
    yield* put(
      rootActions.session.userLoggedIn({
        username,
        permissions: permissions,
      })
    );
    // Navigate to specified page
    yield* put(rootActions.session.routeChanged(history.location));
    // Get site's provision ID
    const provisionId = yield* call(() => client.provisioning.get().promise);
    localStorage.setItem("provisioningId", provisionId);
  }
}

/**
 * Performs a check to see if the user is authenticated
 */
export function* appStartPersistUserAuthentication() {
  let notificationTitle = "";
  i18n.then((t) => {
    notificationTitle = t("message-Authentication error");
  });
  try {
    let authObject = yield* call(getAuthentication);
    const config = yield* call(() => getGlobalConfigs());

    // Always attempt to process the UMRT first as even if there is an authenticated
    // user, as a new user may be attempting to log in via UMRT.
    if (config.autoLogin && getUMRT()) {
      try {
        // Attempt to auth the new UMRT. This may fail if an expired token
        // has already been sent.
        yield* call(userSessionRefreshSaga);
        authObject = yield* call(getAuthentication);
      } catch (exception) {
        // If the new UMRT auth throws and there is already an auth object
        // that can be used to sign in then it should be used. Otherwise throw the
        // exception which will get caught below and display a notification.
        if (authObject === undefined) {
          throw exception;
        }
      }

      if (authObject) {
        const username = yield* getUserNameFromAuth(authObject);
        yield* logInWithAuthObject({ ...authObject, username });
      } else {
        yield* put(
          // @ts-ignore
          rootActions.session.userIsLoggedOutAppStart(history.location.pathname)
        );
      }
    } else if (authObject) {
      yield* logInWithAuthObject(authObject);
    } else {
      yield* put(
        rootActions.session.userIsLoggedOutAppStart(history.location.pathname)
      );
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(notificationTitle, [], "error")
    );
    yield* put(
      // @ts-ignore
      rootActions.session.userIsLoggedOutAppStart(history.location.pathname)
    );
  }
}

function* logInSaga({
  payload: { username, password },
}: PayloadAction<{ username: string; password: string }>) {
  let signInFailed = "",
    authError = "",
    licenceServiceNotRunning = "",
    licenceServiceNotRunningRetry = "",
    licenceServiceRunningAndUnlicensed = "",
    licenceServiceRunningAndUnlicensedRetry = "";
  i18n.then((t) => {
    signInFailed = t("message-signInFailed");
    authError = t("message-Authentication error");
    licenceServiceNotRunning = t(
      "message-theLicenceServiceFailedToConnectToLicenceManager"
    );
    licenceServiceNotRunningRetry = t(
      "message-theLicenceServiceFailedToConnectToLicenceManagerRetry"
    );
    licenceServiceRunningAndUnlicensed = t(
      "message-theLicenceServiceIsRunningButUnlicensed"
    );
    licenceServiceRunningAndUnlicensedRetry = t(
      "message-theLicenceServiceIsRunningButUnlicensedRetry"
    );
  });

  try {
    yield* put(
      // Clear any previous failed login message
      // @ts-ignore
      dismissNotification()
    );
    const client = yield* getContext<CMSClientType>("client");
    const response = yield* call(() => client.system().promise);
    const isLicenceServiceNotRunning =
      (response && response.status === "LicenceServiceNotRunning") ||
      (response && response.status === "LicenceServiceError");
    const isUnlicensed =
      response !== undefined && response.status === "Unlicensed";
    if (isLicenceServiceNotRunning) {
      yield* put(
        rootActions.session.licenceServiceNotRunning({
          message: licenceServiceNotRunning,
          retryMessage: licenceServiceNotRunningRetry,
        })
      );
      clearAuthentication();
    } else if (isUnlicensed) {
      yield* put(
        rootActions.session.unLicencedServiceRunning({
          message: licenceServiceRunningAndUnlicensed,
          retryMessage: licenceServiceRunningAndUnlicensedRetry,
        })
      );
      clearAuthentication();
    } else {
      const authResponse = yield* call(
        (values) => client.auth.access(values).promise,
        {
          client_id: constants.clientId,
          username,
          password,
          scope: "read write delete update offline_access",
          grant_type: "central_credentials",
        }
      );
      if (isAuthFailure(authResponse)) {
        const msg = signInFailed;
        yield* put(
          // @ts-ignore
          setNotification(
            msg,
            [authResponse.error_description.message],
            "error"
          )
        );
      } else {
        // we persist the authentication now as we are going to make another request for user permissions
        yield* call(persistAuthentication, { ...authResponse, username });
        const { sub: subjectId } = yield* call(
          (token) => jwtDecode<{ sub: string }>(token),
          authResponse.access_token
        );
        const permissions = yield* call(getUserPermissions, subjectId);
        if (permissions !== undefined) {
          yield* put(
            rootActions.session.userLoggedIn({
              username,
              permissions: permissions,
            })
          );
          const provisionId = yield* call(
            () => client.provisioning.get().promise
          );
          localStorage.setItem("provisioningId", provisionId);
          yield* call(scheduleAuthRefreshSaga, authResponse.expires_in);
          yield* call(getCoreDataSaga);
        }
      }
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(signInFailed, [authError], "error")
    ),
      yield* put(rootActions.session.userFailedToLogIn());
  }
}

function* getUserPermissions(userId: string) {
  try {
    const client = yield* getContext<CMSClientType>("client");
    return yield* call(async (id) => {
      const user = await client.users.byId(id).promise;

      if (user) {
        const storedAuthObj = getAuthentication();
        if (storedAuthObj) {
          localStorage.removeItem("path");
          localStorage.setItem(
            constants.authLocalStorageKey,
            JSON.stringify({ ...storedAuthObj, username: user.email })
          );
        }
      }

      return user && user.permissions
        ? (Object.keys(user.permissions) as SystemPermissions[]).filter(
            (key) => user.permissions![key]
          )
        : [];
    }, userId);
  } catch (error: any) {
    console.log(error);
  }
}

/** Remove the query string with the specified key from the current location.
 * This deals both with browser-level query strings (before the `#!`) and those within the hash part of the URL (after the `#!`).
 */
function removeQueryString(key: string) {
  const qsRegexp = new RegExp(`\\b${key}=[^&#]*&?`, "gi");

  history.replace({
    ...history.location,
    hash: history.location.hash
      .replace(qsRegexp, "")
      // Also remove a trailing "?"
      .replace(/\?$/, ""),
    search: history.location.search
      .replace(qsRegexp, "")
      // Also remove a trailing "?"
      .replace(/\?$/, ""),
  });
}

/** When the new token is about to expire, refresh it */
function* scheduleAuthRefreshSaga(afterSeconds = 300) {
  // Refresh 30 seconds before the expiry, but avoid refreshing more often than every 25 seconds
  const msToWait = afterSeconds > 55 ? (afterSeconds - 30) * 1000 : 25000;
  yield* delay(msToWait);
  yield* call(userSessionRefreshSaga);
}

/** Use the refresh token to keep the user logged in */
export function* userSessionRefreshSaga(): any {
  // Get refresh token from storage
  const storedAuthObj = yield* call(getAuthentication);
  let refreshToken = storedAuthObj?.refresh_token;
  const config = yield* call(() => getGlobalConfigs());
  const umrt = getUMRT();
  if (config.autoLogin && umrt !== null) {
    refreshToken = umrt;
    removeQueryString("umrt");
  }
  if (refreshToken) {
    try {
      localStorage.setItem("refreshing", JSON.stringify(true));
      // Refresh auth
      const refreshing: Promise<AuthResponse> | null = CMSClient.auth.refresh({
        grant_type: "refresh_token",
        client_id: constants.clientId,
        refresh_token: refreshToken,
      }).promise;

      const newAuthObj = yield* call(() => refreshing);

      if (isAuthFailure(newAuthObj)) {
        throw new Error(`${newAuthObj.error}; ${newAuthObj.error_description}`);
      }
      yield* put(rootActions.session.userSessionRefreshed(newAuthObj));
    } finally {
      localStorage.removeItem("refreshing");
    }
  } else {
    console.error("Unable to refresh auth; no token available");
    removeQueryString("umrt");
  }
}

/** Persist refreshed session auth; schedule next refresh */
function* userSessionRefreshedSaga({ payload }: PayloadAction<AuthResponse>) {
  if (isAuthFailure(payload)) {
    clearAuthentication();
    return;
  }
  const authObj = yield* call(getAuthentication);
  const username = authObj?.username || "";
  yield* call(persistAuthentication, { ...payload, username });
  yield* call(scheduleAuthRefreshSaga, payload.expires_in);
}

/** Retries fetching the auth data (as a string) from storage for up to half a second, in case storage is not ready at page load */
function readAuth(retries = 0): string | null {
  let stored = localStorage.getItem(constants.authLocalStorageKey);
  if (!stored && retries < 50) {
    delay(10);
    stored = readAuth(retries + 1);
  }
  return stored;
}

/** Gets the auth details from local storage (parsed to an object) */
export const getAuthentication = ():
  | (AuthSuccess & { username: string })
  | undefined => {
  try {
    const keyStore = readAuth();

    if (!keyStore) {
      // Not logged in
      return undefined;
    }
    return JSON.parse(keyStore);
  } catch (error) {
    console.warn("Unable to get authentication details from local storage");
    return undefined;
  }
};

const persistAuthentication = (
  authObject: AuthSuccess & { username: string; expiry?: number }
) => {
  // Set an effective expiry time one second before the time sent from the server
  const expiry = Date.now() + authObject.expires_in * 1000;
  authObject = { expiry, ...authObject };
  localStorage.setItem(
    constants.authLocalStorageKey,
    JSON.stringify(authObject)
  );
};

export const clearAuthentication = () => {
  localStorage.removeItem(constants.authLocalStorageKey);
};

export const clearSystem = () => {
  localStorage.removeItem("system");
};

export const persistSystem = (system: System) => {
  localStorage.setItem("system", JSON.stringify(system));
};

export const clearProvisioningId = () => {
  localStorage.removeItem("provisioningId");
};

function* resetPasswordSendLinkSaga({
  payload,
}: PayloadAction<{ email: string; translationTexts: Record<string, string> }>) {
  const {
    resetSent,
    resetSentEmail,
    resetWritten,
    resetWrittenToDisk,
    resetFailed,
  } = payload.translationTexts;

  try {
    const client = yield* getContext<CMSClientType>("client");
    const system = JSON.parse(localStorage.getItem("system")!);

    const isFilesCommsType = system && system.commsType === "Files";
    const successTitle = isFilesCommsType ? resetWritten : resetSent;
    const successMessage = isFilesCommsType
      ? resetWrittenToDisk
      : resetSentEmail;

    yield* call(
      (email) =>
        client.users.resetPasswordSendLink(removeTenancyFromEmailAddress(email))
          .promise,
      payload.email
    );
    yield* put(rootActions.session.passwordResetSuccessfully());

    yield* put(
      // @ts-ignore
      setNotification(successTitle, [successMessage], "success")
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(resetFailed, [getErrorMessage(error)], "error")
    );
  }
}

function* resetPasswordUpdateSaga({
  payload: { newPassword, resetToken },
}: any) {
  let update = "",
    changed = "",
    failed = "";
  i18n.then((t) => {
    update = t("message-passwordUpdateSuccess");
    changed = t("message-Password Changed");
    failed = t("message-passwordChangeFailed");
  });
  try {
    const client = yield* getContext<CMSClientType>("client");
    yield* call((values) => client.users.resetPasswordUpdate(values).promise, {
      newPassword,
      resetToken,
    });
    yield* put(
      // @ts-ignore
      setNotification(update, [changed], "success")
    );
    yield* put(rootActions.session.userPasswordReset());
  } catch (error) {
    yield* put(rootActions.session.userPasswordFailedToReset());
    yield* put(
      // @ts-ignore
      setNotification(failed, [getErrorMessage(error)], "error")
    );
  }
}
