/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  MachineStatus,
  UniqueMeasurementType,
  TimeSeries,
  TimeSeriesValueCompactValue,
  MachineStateSummary,
  ProcessAction,
  TimeSeriesLimit,
  UnitDisplayHint,
  SortDirection,
  FileInfoForSelectedJob,
} from "@centralwebteam/narwhal";
import { ZoomTransform } from "d3-zoom";
import { AlertPresentation } from "@/presentation/Alert";
import { JobPresentation } from "@/presentation/Job";
import {
  AlertTypeReason,
  JobViewType,
  FocusedOrLatestJobViewMeasurementType,
  MachineAnalysisAdditiveViewType,
  MachineAnalysisMetrologyViewType,
  MeasurementPlotType,
  SidebarSection,
  DataLoadingTypes,
} from "@/store/state";
import { Series } from "@/components/SeriesList";
import { MeasurementCharacteristicPresentation } from "@/presentation/MeasurementCharacteristic";
export const machineAnalysisActions = {
  incompleteJobsFetched: createStandardAction(
    "[machine analysis] incomplete jobs fetched"
  )<{ jobs: JobPresentation[] }>(),
  jobSummariesFetched: createStandardAction(
    "[machine analysis][saga] jobs timeline data fetched"
  )<{ jobs: JobPresentation[]; from: string; to: string }>(),
  jobsSummariesDeltaFetched: createStandardAction(
    "[machine analysis] jobs timeline data delta fetched (saga)"
  )<{ jobs: JobPresentation[]; from: string; to: string }>(),
  unitDisplayHintsRequired: createStandardAction(
    "[machine analysis][saga] unit display hints required"
  )(),
  unitDisplayHintsFetchingFinished: createStandardAction(
    "[machine analysis][saga] unit display hints fetching finished"
  )(),
  unitDisplayHintsFetched: createStandardAction(
    "[machine analysis][saga] unit display hints fetched"
  )<{ hints: UnitDisplayHint[]; from: string; to: string }>(),
  unitDisplayHintsDeltaFetched: createStandardAction(
    "[machine analysis][saga] unit display hints delta fetched"
  )<{ hints: UnitDisplayHint[]; from: string; to: string }>(),
  statesFetched: createStandardAction(
    "[machine analysis][saga] states timeline data fetched"
  )<{ states: MachineStatus[]; from: string; to: string }>(),
  statesDeltaFetched: createStandardAction(
    "[machine analysis] states timeline data delta fetched (saga)"
  )<{ states: MachineStatus[]; from: string; to: string }>(),
  alertsFetched: createStandardAction(
    "[machine analysis][saga] alerts timeline data fetched"
  )<{ alerts: AlertPresentation[]; from: string; to: string }>(),
  alertsDeltaFetched: createStandardAction(
    "[machine analysis] alerts timeline data delta fetched (saga)"
  )<{ alerts: AlertPresentation[]; from: string; to: string }>(),
  processActionsFetched: createStandardAction(
    "[machine analysis][saga] process action timeline data fetched"
  )<{ events: ProcessAction[]; from: string; to: string }>(),
  processActionsDeltaFetched: createStandardAction(
    "[machine analysis] process action timeline data delta fetched (saga)"
  )<{ events: ProcessAction[]; from: string; to: string }>(),
  alertsTimelineClicked: createStandardAction(
    "[machine analysis] alerts timeline clicked"
  )<string[]>(),
  alertsTimelinePopupClosed: createStandardAction(
    "[machine analysis] alerts timeline popup closed"
  )(),
  mainVisualisationResizeEventFired: createStandardAction(
    "[machine analysis] main visualisation resize event fired"
  )<{
    width: number;
    height: number;
  }>(),
  lineGraphResizeEventFired: createStandardAction(
    "[machine analysis] line graph resize event fired"
  )<{
    width: number;
    height: number;
  }>(),
  jobTimelineClicked: createStandardAction(
    "[machine analysis] job timeline clicked"
  )<{
    id: string;
  }>(),
  timelineZoomed: createStandardAction("[machine analysis] timeline zoomed")<{
    t: ZoomTransform;
  }>(),
  yAxisZoomed: createStandardAction("[machine analysis] y axis zoomed")<{
    t: ZoomTransform;
    id: string;
    type: "sensor" | "measurement";
  }>(),
  yDeviationAxisZoomed: createStandardAction(
    "[machine analysis] y deviation axis zoomed"
  )<{
    t: ZoomTransform;
  }>(),
  yAxisResetClicked: createStandardAction(
    "[machine analysis] y axis reset clicked"
  )<{ id: string; type: "measurement" | "sensor" }>(),
  yAxisDeviationResetClicked: createStandardAction(
    "[machine analysis] y axis deviation reset clicked"
  )(),
  focusedJobViewTypeSwitchChanged: createStandardAction(
    "[machine analysis] focusedJobViewTypeSwitchChanged"
  )<{ value: JobViewType }>(),
  focusedJobViewMeasurementTypeSwitchChanged: createStandardAction(
    "[machine analysis] focusedJobViewMeasurementTypeSwitchChanged"
  )<{ value: FocusedOrLatestJobViewMeasurementType }>(),
  latestJobViewMeasurementTypeSwitchChanged: createStandardAction(
    "[machine analysis] latestJobViewMeasurementTypeSwitchChanged"
  )<{ value: FocusedOrLatestJobViewMeasurementType }>(),
  machineSelectorMachineClicked: createStandardAction(
    "[machine analysis] machine selector machine clicked"
  )<string>(),
  machineSelectorCancelButtonClicked: createStandardAction(
    "[machine analysis] machine selector cancel button clicked"
  )(),
  machineSelectorSelectButtonClicked: createStandardAction(
    "[machine analysis] machine selector select button clicked"
  )<string>(),
  machineSelectorButtonClicked: createStandardAction(
    "[machine analysis] machine selector machine selector button clicked"
  )(),
  machineSelectorEscapeKeyPressed: createStandardAction(
    "[machine analysis] machine selector escape key pressed"
  )<void>(),
  jobsListCheckboxClicked: createStandardAction(
    "[machine analysis] jobs list checkbox clicked"
  )<string>(),
  alertTypeParetoClicked: createStandardAction(
    "[machine analysis] alert type pareto clicked"
  )<AlertTypeReason>(),
  alertParetoTableCheckboxClicked: createStandardAction(
    "[machine analysis] alert pareto table checkbox clicked"
  )<{ alertName: string }>(),
  measurementTypesFetched: createStandardAction(
    "[machine analysis][saga] measurement types fetched"
  )<{
    types: UniqueMeasurementType[];
  }>(),
  measurementTypesDeltaFetched: createStandardAction(
    "[machine analysis][saga] measurement types delta fetched"
  )<{
    types: UniqueMeasurementType[];
  }>(),
  measurementTypeSeriesListItemClicked: createStandardAction(
    "[machine analysis] measurement types series list item clicked"
  )<{ series: Series }>(),
  measurementValuesFetched: createStandardAction(
    "[machine analysis][saga] measurement values fetched"
  )<{
    featureName?: string;
    name: string;
    from: string;
    to: string;
    data: MeasurementCharacteristicPresentation[];
  }>(),
  measurementValuesDeltaFetched: createStandardAction(
    "[machine analysis][saga] measurement values delta fetched"
  )<{
    featureName?: string;
    name: string;
    from: string;
    to: string;
    data: MeasurementCharacteristicPresentation[];
  }>(),
  measurementPlotTypeChanged: createStandardAction(
    "[machine analysis] measurement plot type changed"
  )<{ value: MeasurementPlotType }>(),
  timeSeriesTypesSeriesListItemClicked: createStandardAction(
    "[machine analysis] time series types series list item clicked"
  )<{ series: Series }>(),
  timeSeriesTypesFetched: createStandardAction(
    "[machine analysis][saga] time series types fetched"
  )<{
    types: TimeSeries[];
  }>(),
  timeSeriesTypesDeltaFetched: createStandardAction(
    "[machine analysis][saga] time series types delta fetched"
  )<{
    types: TimeSeries[];
  }>(),
  timeSeriesLimitsFetched: createStandardAction(
    "[machine analysis][saga] time series limits fetched"
  )<{
    id: string;
    from: string;
    to: string;
    data: TimeSeriesLimit[];
  }>(),
  timeSeriesLimitsDeltaFetched: createStandardAction(
    "[machine analysis][saga] time series limits delta fetched"
  )<{
    id: string;
    from: string;
    to: string;
    data: TimeSeriesLimit[];
  }>(),
  timeSeriesValuesFetched: createStandardAction(
    "[machine analysis][saga] time series values fetched"
  )<{
    id: string;
    from: string;
    to: string;
    data: TimeSeriesValueCompactValue[];
  }>(),
  timelineStaticDataWarningLimitBreached: createStandardAction(
    "[machine analysis][saga] timeline static data warning limit breached"
  )(),
  statesSummaryFetched: createStandardAction(
    "[machine analysis] states summary fetched"
  )<MachineStateSummary[]>(),
  machineAnalysisTabSwitched: createStandardAction(
    "[machine analysis] machine analysis tab switched"
  )<{
    tab: MachineAnalysisAdditiveViewType | MachineAnalysisMetrologyViewType;
  }>(),
  nonAdditiveViewTypeSelected: createStandardAction(
    "[machine analysis] (effect) non additive view type selected"
  )(),
  timeSeriesValuesDeltaFetched: createStandardAction(
    "[machine analysis][saga] time series values delta fetched"
  )<{
    id: string;
    from: string;
    to: string;
    data: TimeSeriesValueCompactValue[];
  }>(),
  setFocusedJobReducedEndDate: createStandardAction(
    "[machine analysis] set focused job reduced end date"
  )<{
    date: Date;
  }>(),
  liveRefreshCompleted: createStandardAction(
    "[machine analysis][saga] live refresh completed"
  )<{
    date: string;
  }>(),
  mostRecentJobUpdated: createStandardAction(
    "[machine analysis][saga] most recent job fetched"
  )<{
    job: JobPresentation;
    moreDetails: any;
    filesInfoForSelectedJob: FileInfoForSelectedJob[];
  }>(),
  relatedMostRecentJobFetched: createStandardAction(
    "[machine analysis][saga] related most recent job fetched"
  )<{ job: JobPresentation }>(),
  sidebarSectionHeaderClicked: createStandardAction(
    "[machine analysis] sidebar section header clicked"
  )<{ section: SidebarSection }>(),
  measurementTableMeasurementRowClicked: createStandardAction(
    "[machine analysis] measurement table measurement row clicked"
  )<{ measurement: MeasurementCharacteristicPresentation }>(),
  multiJobTableMeasurementTypeClicked: createStandardAction(
    "[machine analysis] multi job table measurement type clicked"
  )<{ measurement: { featureName?: string; name: string } }>(),
  sidebarQueryChanged: createStandardAction(
    "[machine analysis] sidebar section query changed"
  )<{ section: SidebarSection; query: string | undefined }>(),
  measurementTypeSeriesListItemMainClicked: createStandardAction(
    "[machine analysis] measurement type series list item main clicked"
  )<{ series: Series }>(),
  fetchingStarted: createStandardAction("[machine analysis] fetching started")<{
    loadingType: DataLoadingTypes;
  }>(),
  fetchingFinished: createStandardAction(
    "[machine analysis] fetching finished"
  )<{
    to: string;
    loadingType: DataLoadingTypes;
  }>(),
  multiJobTableHeadingClicked: createStandardAction(
    "[machine analysis] multi job table heading clicked"
  )<{
    sortColumn: Date;
    sortDirection: "asc" | "desc";
    jobType: "Focused" | "Latest";
  }>(),
  relatedLatestJobFetched: createStandardAction(
    "[[machine analysis][saga] related latest job fetched"
  )<{ job: JobPresentation }>(),
  dataInvalidated: createStandardAction(
    "[machine analysis] data invalidated"
  )(),
  setFocusedJobSingleJobTableHeadingColumnClicked: createStandardAction(
    "[machine analysis] change focused job single job table heading column clicked"
  )<[string, SortDirection]>(),
  jobSelectedManually: createStandardAction(
    "[machine analysis] job selected manually from jobs timeline"
  )<{ selected: boolean }>(),
};

export type MachineAnalysisActions = ActionType<typeof machineAnalysisActions>;
