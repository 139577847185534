/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { assertUnreachable } from "@/@types/assertUnreachable";
import { JobPresentation } from ".";
import { useTranslation } from "react-i18next";

export const statuses = [
  "Completed",
  "Aborted",
  "Started",
  "No Job End",
  "No Master End",
] as const;
export type Status = (typeof statuses)[number];

function validateStringAsStatus(statusString: string): statusString is Status {
  return statuses.includes(statusString as any);
}

export function mapStringToStatus(type: string, statusString?: string): Status {
  if (statusString && validateStringAsStatus(statusString)) return statusString;
  return type === "mastering" ? "No Master End" : "No Job End";
}

export const statusGroups = ["Completed", "Incomplete"] as const;
export type StatusGroup = (typeof statusGroups)[number];

export const mapStatusToGroup = ({ status }: JobPresentation): StatusGroup => {
  switch (status) {
    case "Completed": {
      return "Completed";
    }
    case "Aborted":
    case "No Job End":
    case "No Master End":
    case "Started": {
      return "Incomplete";
    }
    default: {
      assertUnreachable(status);
    }
  }
};

export const MapStatusToDisplayString = (job: JobPresentation): string => {
  const { t } = useTranslation();
  return mapStatusToGroup(job) === "Completed"
    ? t("label-Completed")
    : t("label-Incomplete");
};
