/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { rootActions, RootState } from "@/store";
import {
  getContext,
  call,
  put,
  fork,
  delay,
  all,
  takeEvery,
  takeLatest,
  race,
  take,
  cancel,
  cancelled,
  select,
} from "typed-redux-saga";
import i18n from "@/i18n";
import { pickBy } from "lodash/fp";
import { getType } from "typesafe-actions";
import { getErrorMessage } from "@/axios/errors";
import { CMSClientType } from "@/cms-api";
import {
  Client,
  ClientPatch,
  EntityPermission,
  EntityPermissionExtended,
} from "@centralwebteam/narwhal";
import { getGlobalConfigs } from "@/index";
import { setNotification } from "@/store/global/thunks";
import { notificationTypes } from "@centralwebteam/jellyfish";
import { notUndefined } from "@/@types/guards/notUndefined";
import { t } from "i18next";

export function* rootSaga() {
  yield* fork(clientsRefreshLoop);
  yield* all([
    takeEvery(
      getType(rootActions.manageClients.activateClientButtonClicked),
      activateClientSaga
    ),
    takeEvery(
      getType(rootActions.manageClients.deactivateClientButtonClicked),
      deactivateClientSaga
    ),
    takeLatest(
      getType(rootActions.manageClients.saveChangesButtonClicked),
      updateClientSaga
    ),
    takeEvery(
      getType(rootActions.manageClients.deleteClientButtonClicked),
      deleteClientSaga
    ),
    takeLatest(
      getType(rootActions.manageClients.createNewClientButtonClicked),
      createClientSaga
    ),
  ]);
}

function* clientsRefreshLoop() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* call(getClientsSaga);
    yield* delay(config.refreshRateLiveInMS);
  }
}
export function* getClientsSaga() {
  try {
    const client: CMSClientType = yield getContext("client");
    const clients = yield* call(() => client.clients.all().promise);
    yield* put(rootActions.manageClients.clientsFetched(clients));
  } catch (error) {
    console.error(error);
  }
}

export function* activateClientSaga({ payload: client }: any) {
  let clientActivated = "",
    activated = "",
    activateFailed = "";
  i18n.then((t) => {
    clientActivated = t("message-Client Activated");
    activated = t("message-namedObjectActivated", client) as string;
    activateFailed = t("message-clientActivateFailed");
  });
  try {
    const CMSClient: CMSClientType = yield getContext("client");

    yield* call((user) => CMSClient.clients.activate(user.id).promise, client);
    yield* put(
      // @ts-ignore
      setNotification(clientActivated, [activated], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(activateFailed, [], notificationTypes.error)
    );
  } finally {
    yield* call(getClientsSaga);
  }
}

export function* deactivateClientSaga({ payload: client }: any) {
  let clientDeactivated = "",
    deactivated = "",
    deactivateFailed = "";
  i18n.then((t) => {
    clientDeactivated = t("message-Client Deactivated");
    deactivated = t("message-namedObjectDeactivated", client) as string;
    deactivateFailed = t("message-clientDeactivateFailed");
  });
  try {
    const CMSClient: CMSClientType = yield getContext("client");
    yield* call(
      (user) => CMSClient.clients.deactivate(user.id).promise,
      client
    );
    yield* put(
      // @ts-ignore
      setNotification(
        clientDeactivated,
        [deactivated],
        notificationTypes.success
      )
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        deactivateFailed,
        [getErrorMessage(error)],
        notificationTypes.error
      )
    );
  } finally {
    yield* call(getClientsSaga);
  }
}

export function* updateClientSaga({ payload }: any) {
  const client: Client = payload.client;
  const selectedConnectionType: string = payload.selectedConnectionType;
  const CMSClient: CMSClientType = yield getContext("client");
  const clients = yield* select(
    (state: RootState) => state.manageClients.clients
  );
  const selectedClientId = yield* select(
    (state: RootState) => state.manageClients.selectedClientId
  );
  const selectedClient = clients.find((c) => c.id === selectedClientId);
  const updateKeys = ["name", "id", "active"];
  const updateClient = pickBy(
    (_value, key) => updateKeys.includes(key),
    client
  ) as unknown as ClientPatch;
  const existingSelectedClientName = selectedClient?.name;
  let clientUpdated = "",
    clientUpdateFailed = "",
    clientPermissionUpdateFailed = "",
    clientMachinePermissionSetFailed = "",
    clientLocationPermissionSet = "",
    clientLocationPermissionSetFailed = "";
  i18n.then((t) => {
    clientUpdated = t("message-Client Updated");
    clientUpdateFailed = t("message-clientUpdateFailed");
    clientPermissionUpdateFailed = t("message-clientPermissionUpdateFailed");
    clientMachinePermissionSetFailed = t(
      "message-clientMachinePermissionSetFailed"
    );
    clientLocationPermissionSet = t("message-clientLocationPermissionSet");
    clientLocationPermissionSetFailed = t(
      "message-clientLocationPermissionSetFailed"
    );
  });
  try {
    if (existingSelectedClientName !== updateClient.name) {
      yield* call(
        (updateClient) => CMSClient.clients.update(updateClient).promise,
        updateClient
      );
      yield* put(
        // @ts-ignore
        setNotification(clientUpdated, [client.name], notificationTypes.success)
      );
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        clientUpdateFailed,
        // @ts-ignore
        [error.message],
        notificationTypes.error
      )
    );
  }
  try {
    if (
      selectedConnectionType !== t`clientType.User based connection` &&
      client.permissions !== undefined
    ) {
      yield* call(
        (client) =>
          CMSClient.clients.updatePermissions(client.id, client.permissions!)
            .promise,
        client
      );
      yield* put(
        // @ts-ignore
        setNotification(clientUpdated, [client.name], notificationTypes.success)
      );
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        clientPermissionUpdateFailed,
        [client.name],
        notificationTypes.error
      )
    );
  }
  try {
    if (selectedConnectionType === t`clientType.Machine based connection`) {
      const machinePermissions: EntityPermissionExtended[] =
        client.machineEntityPermissions;
      if (machinePermissions.length) {
        const selectedClientExistingMachinePermissionsIds = (
          client.oldMachineEntityPermissions || []
        ).map((m: any) => m.entityId);
        if (selectedClientExistingMachinePermissionsIds.length) {
          yield* call(permission.deleteMachineEntityPermission, {
            clientId: client.id,
            selectedClientExistingMachinePermissionsIds,
          });
        }
        if (machinePermissions.length) {
          yield* call(permission.updateMachineEntityPermission, {
            clientId: client.id,
            readAdminClientMachine: machinePermissions,
          });
        }
      }
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        clientMachinePermissionSetFailed,
        [getErrorMessage(error)],
        notificationTypes.error
      )
    );
  }
  try {
    if (
      payload.selectedConnectionType === t`clientType.User based connection`
    ) {
      const selectedClientExistingScopes = selectedClient?.scopes;
      const scopesToBeDeleted = selectedClientExistingScopes?.filter(
        (s) => !client.scopes?.includes(s)
      );
      if (scopesToBeDeleted?.length) {
        yield* call(
          (id) =>
            CMSClient.clients.deleteClientScope(id, scopesToBeDeleted).promise,
          client.id
        );
      }
      const scopesToBeAdded = client.scopes?.filter(
        (s: any) => !selectedClientExistingScopes?.includes(s)
      );
      if (scopesToBeAdded?.length) {
        const clientScopes = {
          id: updateClient.id!,
          scopes: scopesToBeAdded,
        };
        yield* call(
          (clientScopes) =>
            CMSClient.clients.addClientScopes(
              clientScopes.id,
              clientScopes.scopes
            ).promise,
          clientScopes
        );
      }
    }
    yield* put(
      // @ts-ignore
      setNotification(clientUpdated, [client.name], notificationTypes.success)
    );
  } catch (error) {
    console.log(error);
  }
  try {
    if (
      payload.selectedConnectionType === t`clientType.Location based connection`
    ) {
      const locationPermissions: EntityPermission[] =
        client.locationEntityPermissions;
      if (locationPermissions.length > 0) {
        // Delete permissions marked as "None"
        // Permission inheritance rules mean we need to delete the existing read/admin permission before adding
        const noPermissionLocationIds = locationPermissions
          .filter(notUndefined)
          .filter(({ permission }) => permission === "None")
          .map((item) => item.entityId);
        if (noPermissionLocationIds.length > 0) {
          yield* call(permission.deleteLocationEntityPermission, {
            clientId: client.id,
            locationIds: noPermissionLocationIds,
          });
        }
        // Save all Read and Admin permissions
        const readAdminClientLocations = locationPermissions.filter(
          ({ permission }) => ["Read", "Admin"].includes(permission)
        );
        if (readAdminClientLocations.length > 0) {
          yield* call(permission.updateLocationEntityPermission, {
            clientId: client.id,
            readAdminClientLocations,
          });
        }
        yield* put(
          // @ts-ignore
          setNotification(
            clientLocationPermissionSet,
            [client.name],
            notificationTypes.success
          )
        );
      }
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        clientLocationPermissionSetFailed,
        [client.name],
        notificationTypes.error
      )
    );
  } finally {
    if (!(yield* cancelled())) {
      yield* call(getClientsSaga);
    }
  }
}

const permission = {
  updateLocationEntityPermission: function* updateLocationEntityPermission({
    clientId,
    readAdminClientLocations,
  }: {
    clientId: string;
    readAdminClientLocations: EntityPermission[];
  }) {
    const CMSClient: CMSClientType = yield getContext("client");
    yield* call(
      (clientId) =>
        CMSClient.clients.updateLocationPermissions(
          clientId,
          readAdminClientLocations
        ).promise,
      clientId
    );
  },
  deleteLocationEntityPermission: function* deleteLocationEntityPermission({
    clientId,
    locationIds,
  }: {
    clientId: string;
    locationIds: string[];
  }) {
    const CMSClient: CMSClientType = yield getContext("client");
    yield* call(
      (clientId) =>
        CMSClient.clients.deleteLocationPermissions(clientId, locationIds)
          .promise,
      clientId
    );
  },
  updateMachineEntityPermission: function* updateLocationEntityPermission({
    clientId,
    readAdminClientMachine,
  }: {
    clientId: string;
    readAdminClientMachine: {
      entityId: string;
      permission: string;
    }[];
  }) {
    const CMSClient: CMSClientType = yield getContext("client");
    if (
      Object.values(readAdminClientMachine[0]).some(
        (value) => value === undefined
      )
    )
      return;

    yield* call(
      (clientId) =>
        CMSClient.clients.updateMachinePermissions(
          clientId,
          readAdminClientMachine
        ).promise,
      clientId
    );
  },
  deleteMachineEntityPermission: function* deleteMachineEntityPermission({
    clientId,
    selectedClientExistingMachinePermissionsIds,
  }: {
    clientId: string;
    selectedClientExistingMachinePermissionsIds: string[];
  }) {
    const CMSClient: CMSClientType = yield getContext("client");
    yield* call(
      (selectedClientExistingMachinePermissionsIds) =>
        CMSClient.clients.deleteMachinePermissions(
          clientId,
          selectedClientExistingMachinePermissionsIds
        ).promise,
      selectedClientExistingMachinePermissionsIds
    );
  },
};

export function* deleteClientSaga({ payload: client }: any) {
  let clientDeleted = "",
    deleted = "",
    deleteFailed = "";
  i18n.then((t) => {
    clientDeleted = t("message-Client Deleted");
    deleted = t("message-namedObjectDeleted", client) as string;
    deleteFailed = t("message-clientDeleteFailed");
  });
  try {
    yield* put(rootActions.manageClients.confirmDeletion());
    const { cancelled } = yield* race({
      cancelled: take(getType(rootActions.manageClients.cancelButtonClicked)),
      confirmed: take(getType(rootActions.manageClients.confirmButtonClicked)),
    });
    if (cancelled) {
      yield* cancel();
    }
    const CMSClient: CMSClientType = yield getContext("client");
    yield* call(
      (client) => CMSClient.clients.delete(client.id).promise,
      client
    );
    yield* put(
      // @ts-ignore
      setNotification(clientDeleted, [deleted], notificationTypes.success)
    );
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(deleteFailed, [], notificationTypes.error)
    );
  } finally {
    if (!(yield* cancelled())) {
      yield* call(getClientsSaga);
    }
  }
}

export function* createClientSaga({ payload }: any) {
  let connectorCreated = "",
    clientPermissionSetFailed = "",
    clientMachinePermissionSetFailed = "",
    clientCreateFailed = "";
  i18n.then((t) => {
    connectorCreated = t("message-Connector created");
    clientPermissionSetFailed = t("message-clientPermissionSetFailed");
    clientMachinePermissionSetFailed = t(
      "message-clientMachinePermissionSetFailed"
    );
    clientCreateFailed = t("message-clientCreateFailed");
  });
  try {
    const {
      machineEntityPermissions,
      locationEntityPermissions,
      ...newClient
    } = payload.newClient;
    const client: CMSClientType = yield getContext("client");
    const response = yield* call(
      (props: ClientPatch) => client.clients.add(props).promise,
      newClient
    );

    // @ts-ignore
    const { id, secret } = response[0];
    yield* put(
      // @ts-ignore
      setNotification(
        connectorCreated,
        [newClient.name],
        notificationTypes.success
      )
    );
    yield* put(
      rootActions.manageClients.clientCreated({
        clientId: id,
        name: newClient.name,
        clientSecret: secret ?? undefined,
      })
    );

    try {
      if (
        payload.selectedConnectionType ===
        t`clientType.Location based connection`
      ) {
        // if there are location entity permissions set them on the newly created client
        if (locationEntityPermissions.length) {
          // as this is a brand new client with no existing permissions we don't need to deal with "none" (deletes)
          const readAdminClientLocations = locationEntityPermissions.filter(
            (item: EntityPermission) => item.permission.toLowerCase() !== "none"
          );
          if (readAdminClientLocations.length > 0)
            yield* call(permission.updateLocationEntityPermission, {
              clientId: id,
              readAdminClientLocations,
            });
        }
      }
    } catch (error) {
      yield* put(
        // @ts-ignore
        setNotification(
          clientPermissionSetFailed,
          [getErrorMessage(error)],
          notificationTypes.error
        )
      );
    }
    try {
      if (
        payload.selectedConnectionType ===
        t`clientType.Machine based connection`
      ) {
        if (machineEntityPermissions.length) {
          yield* call(permission.updateMachineEntityPermission, {
            clientId: id,
            readAdminClientMachine: machineEntityPermissions,
          });
        }
      }
    } catch (error) {
      yield* put(
        // @ts-ignore
        setNotification(
          clientMachinePermissionSetFailed,
          [getErrorMessage(error)],
          notificationTypes.error
        )
      );
    }
  } catch (error) {
    yield* put(
      // @ts-ignore
      setNotification(
        clientCreateFailed,
        [getErrorMessage(error)],
        notificationTypes.error
      )
    );
  } finally {
    if (!(yield* cancelled())) {
      yield* call(getClientsSaga);
    }
  }
}
