/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { MachineState, matchToMachineState } from "@centralwebteam/narwhal";
import { StateBackgrounds } from "@centralwebteam/jellyfish";
import { getColour } from "@/modules/colours/colours";
import { Verdict, Status, StatusGroup } from "./Job";

export const stateColours = {
  // Active
  [MachineState.Running]: "#8EE43F",
  [MachineState.Measuring]: "#8EE43F",
  [MachineState.Active]: "#8EE43F",
  [MachineState.Calibrating]: "#66A42D",
  [MachineState.CalibratingProbes]: "#66A42D",
  [MachineState.Mastering]: "#F9EE80",
  [MachineState.Build]: "#CCD84F",
  [MachineState.Cooling]: "#5CBA9E",
  // Ready
  [MachineState.Idle]: "#FF9933",
  [MachineState.ForcedIdle]: "#FF9933",
  [MachineState.Ready]: "#FF9933",
  // Wait
  [MachineState.AwaitingInput]: "#84E3FF",
  [MachineState.Wait]: "#84E3FF",
  // Interrupted
  [MachineState.Error]: "#FF626D",
  [MachineState.Interrupted]: "#FF626D",
  // Stopped
  [MachineState.Offline]: "#E6E6E6",
  [MachineState.Stopped]: "#FF626D",
  [MachineState.PoweredDown]: "#C9C9C9",
  [MachineState.Maintenance]: "#939DCF",
  [MachineState.Setup]: "#C46B90",
  [MachineState.Teardown]: "#E081E8",
  // Unknown
  [MachineState.Unknown]: "#888",
  [MachineState.Other]: "#646464",
  // RAMTIC
  [MachineState.MICG]: "#182",
  [MachineState.ToolLoad]: "#5dd",
  [MachineState.ToolUnload]: "#746cc0",
  [MachineState.WingTransfer]: "#25a",
  [MachineState.Machining]: "#1e3",
  [MachineState.CarouselEnd]: "#7a30a0",
};

export function getStateColour(
  state: MachineState | string | null | undefined
) {
  if (state === null || state === undefined) {
    return stateColours[MachineState.Unknown];
  }

  const machineState = matchToMachineState(state);
  return machineState ? stateColours[machineState] : getColour(state);
}

export function getStateBackground(
  state: MachineState | string | null | undefined
) {
  if (state === null || state === undefined) {
    return stateColours[MachineState.Unknown];
  }

  const machineState = matchToMachineState(state);
  switch (machineState) {
    case MachineState.Idle:
    case MachineState.ForcedIdle:
      return `url(${StateBackgrounds.idle})`;
    case MachineState.Running:
    case MachineState.Measuring:
      return `url(${StateBackgrounds.running})`;
    case MachineState.Stopped:
    case MachineState.Error:
      return `url(${StateBackgrounds.stopped})`;
    default:
      return getStateColour(state);
  }
}

export const jobStatusColours: Record<Status, string> = {
  Aborted: "#C46B90", // Onyx Merlot
  Completed: "#5CBAB6", // Onyx sea-green is "#5CBA9E", this is a bit less clashing
  Started: "#b0b0b0",
  "No Job End": "#C46B90",
  "No Master End": "#FF626D",
};
export const measurementVerdictColours: Record<Verdict, string> = {
  Fail: "#FF626D",
  NotApplicable: "#009CE0",
  NotToleranced: "#009CE0",
  Pass: "#8EE43F",
  Warning: "#FB963A",
  "No Verdict": "#009CE0",
};

/**
 * This is here for historic/future usecases as currently returns the same colour
 */
export const getJobForegroundColour = (_verdict: Verdict, _status?: Status) => {
  return "black";
};

export const getJobBackgroundColour = (
  verdict: Verdict,
  status?: Status,
  type?: string
) => {
  if (type?.toLowerCase() === "mastering" && status === "Completed") {
    return stateColours.Mastering;
  }
  if (status === "Completed" && verdict)
    return measurementVerdictColours[verdict];
  else return status ? jobStatusColours[status] : "transparent";
};

export const getMeasurementVerdictColour = (
  verdict?: Verdict | string,
  fallback = "transparent"
) => {
  if (!verdict) return fallback;
  // @ts-ignore – if it's a string, that's OK, we have a fallback.
  return measurementVerdictColours[verdict] ?? fallback;
};

export const getMeasurementVerdictBackgroundColour = (verdict: Verdict) => {
  return measurementVerdictColours[verdict];
};

export const statusGroupColours: Record<StatusGroup, string> = {
  Completed: jobStatusColours.Completed,
  Incomplete: jobStatusColours.Aborted,
};
