/*
 * © 2024 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */

/*
  The problem here was that the names used to identify rows were a simple
  concatenation of job.name + machine.id. So if you had a job called "widget"
  and one called "widget2", but "widget" was made on a machine with an ID
  starting with 2, both would open if either was clicked.
*/

/** Combine the job name and machine ID using a separator string to avoid name collisions */
export const getRowId = (jobType: { name: string }, machine: { id: string }) =>
  `${jobType.name}|~|${machine.id}`;

/** Check whether a rowId matches a job, avoiding name collisions */
export const matchesJob = (rowId: string, jobType: { name: string }) =>
  rowId.startsWith(`${jobType.name}|~|`);
