/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  select,
  getContext,
  call,
  put,
  race,
  take,
  delay,
  all,
} from "typed-redux-saga";
import { selectStartAndEnd, selectIsRollingMode } from "@/store/selectors";
import { CMSClientType } from "@/cms-api";
import { Alert, Machine, MachineStateSummary } from "@centralwebteam/narwhal";
import { getGlobalConfigs } from "@/index";
import { selectMachines } from "@/store/filter/selectors";
import { rootActions } from "..";
import { toUTCISOString } from "../../modules/dateFormats/index";
import { getMachineJobAggregate, getMachineJobs } from "./jobAggregate.worker";
import { machinePerformanceActions } from "./actions";
import { chunk, groupBy, orderBy } from "lodash/fp";
import { createJobPresentation } from "@/presentation/Job";
import { AlertPresentation } from "@/presentation/Alert";

const MACHINE_CHUNK_AMOUNT = 10;

/**
 * Fetches data for filtered machines between the from/to dates.
 */
export function* fetchStaticData() {
  const config = yield* call(() => getGlobalConfigs());
  // we should set an idle timeout if the user is flicking between rolling modes
  yield* delay(config.userLoaderTimeInMS);
  yield* put(machinePerformanceActions.fetchingStarted());
  const [from, to] = yield* select(selectStartAndEnd);
  try {
    const machines = yield* select(selectMachines);
    const chunkedMachines = chunk(MACHINE_CHUNK_AMOUNT, machines);
    yield* all([
      ...chunkedMachines.map((chunk) =>
        all([
          call(fetchMachinesJobAggregates, chunk, from, to, "static"),
          call(fetchMachinesStateSummaries, chunk, from, to, "static"),
        ])
      ),
      call(fetchMachinesAlertCounts, machines, from, to, "static"),
    ]);
  } catch (error) {
    console.info(
      "something went wrong fetching static machine performance data",
      error
    );
  } finally {
    yield* put(
      machinePerformanceActions.fetchingFinished({
        to,
      })
    );
  }
}

// LIVE REFRESH

/**
 * Waits for conditions to begin delta loop.
 */
export function* liveRefreshWatcher() {
  const config = yield* call(() => getGlobalConfigs());
  yield* delay(config.refreshRateLiveInMS);
  while (true) {
    yield* call(waitForDateMode, "rolling");
    yield* race({
      cancel: call(waitForDateMode, "static"),
      fetchDelta: call(fetchDeltaSupervisor),
    });
  }
}

/**
 * Terminates when date mode matches params.mode.
 */
function* waitForDateMode(mode: "static" | "rolling") {
  const isRollingMode: boolean = yield* select(selectIsRollingMode);
  if (
    (isRollingMode && mode === "rolling") ||
    (!isRollingMode && mode === "static")
  ) {
    return;
  }
  while (yield* take(rootActions.session.startEndUpdated)) {
    const isRollingMode: boolean = yield* select(selectIsRollingMode);
    if (
      (isRollingMode && mode === "rolling") ||
      (!isRollingMode && mode === "static")
    ) {
      return;
    }
  }
}

/**
 * Controls when to call fetchDeltas saga
 */
function* fetchDeltaSupervisor() {
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    yield* call(fetchDeltas);
    yield* delay(config.refreshRateLiveInMS);
  }
}

/**
 * Fetches the deltas for datasets which need refreshing.
 */
function* fetchDeltas() {
  const now = toUTCISOString(new Date());
  try {
    const lastFetched = yield* select(
      (state) => state.machinePerformance.lastFetched
    );
    // comply with types
    if (!lastFetched) {
      return;
    }
    yield* put(machinePerformanceActions.fetchingStarted());
    const machines = yield* select(selectMachines);
    const chunkedMachines = chunk(MACHINE_CHUNK_AMOUNT, machines);
    yield* all([
      ...chunkedMachines.map((chunk) =>
        all([
          call(fetchMachinesJobAggregates, chunk, lastFetched, now, "delta"),
          call(fetchMachinesStateSummaries, chunk, lastFetched, now, "delta"),
        ])
      ),
      call(fetchMachinesAlertCounts, machines, lastFetched, now, "delta"),
    ]);
  } catch (error) {
    console.log(error);
  } finally {
    yield* put(
      machinePerformanceActions.fetchingFinished({
        to: now,
      })
    );
  }
}

/**
 * Fetches job summaries and aggregates into summary (running etc.) for supplied machines.
 * Puts an action to the store.
 */
function* fetchMachinesJobAggregates(
  machines: Machine[],
  from: string,
  to: string,
  type: "static" | "delta"
) {
  const config = yield* call(() => getGlobalConfigs());
  const client: CMSClientType = yield getContext("client");
  const top = yield* select((state) => state.machinePerformance.top);
  const machineIds = machines.map((machine) => machine.id);
  const summaryStats = yield* call(
    (query) => client.jobs.tempRawSummary(query).promise,
    {
      machines: machineIds,
      from,
      to,
      take: config.maxTake,
    }
  );

  try {
    for (const machineId of machineIds) {
      const data = yield* call(
        getMachineJobAggregate,
        summaryStats[machineId]?.map((job) => createJobPresentation(job)) ?? []
      );

      if (type === "static") {
        const topJobStats = yield* call(
          getMachineJobs,
          summaryStats[machineId]?.map((job) => createJobPresentation(job)) ??
            [],
          top
        );
        const payload = {
          jobAggregate: data,
          topJobStats: topJobStats,
          from,
          to,
          machineId,
        };
        yield* put(rootActions.machinePerformance.jobAggregateFetched(payload));
      } else {
        const payload = {
          jobAggregate: data,
          from,
          to,
          machineId,
        };
        yield* put(
          rootActions.machinePerformance.jobAggregateDeltaFetched(payload)
        );
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * Fetches the state summaries for supplied machines.
 * Puts an action to the store.
 */
function* fetchMachinesStateSummaries(
  machines: Machine[],
  from: string,
  to: string,
  type: "static" | "delta"
) {
  const client: CMSClientType = yield getContext("client");
  let stateSummaries = [] as MachineStateSummary[];
  try {
    stateSummaries = yield* call(
      (query) => client.machines.stateSummary(query).promise,
      {
        machineIds: machines.map((machine) => machine.id),
        from,
        to,
      }
    );
    for (const machineStateSummary of stateSummaries) {
      const payload = {
        statuses: machineStateSummary.stateSeconds,
        machineId: machineStateSummary.machineId,
        from,
        to,
      };
      yield* put(
        type === "static"
          ? rootActions.machinePerformance.statusesFetched(payload)
          : rootActions.machinePerformance.statusesDeltaFetched(payload)
      );
    }
  } catch (ex) {
    console.warn(ex);
  }
}

/**
 * Fetches and counts the error and warning alerts for supplied machines.
 * Puts an action to the store.
 */
function* fetchMachinesAlertCounts(
  machines: Machine[],
  from: string,
  to: string,
  type: "static" | "delta"
) {
  const config = yield* call(() => getGlobalConfigs());
  const client: CMSClientType = yield getContext("client");
  for (const machine of machines) {
    let alerts: Alert[];

    try {
      alerts = yield* call(
        (from, to, machineId, take) =>
          client.events.alerts.errors(from, to, machineId, take).promise,
        from,
        to,
        machine.id,
        config.maxTake
      );
    } catch (ex) {
      console.warn(ex);
      continue;
    }

    const allActiveAlerts = alerts.map((alert) => new AlertPresentation(alert));
    const groupErrorAlerts = groupBy((alert) => alert.name, allActiveAlerts);
    const machineAlerts = orderBy(
      ["count", "name"],
      ["desc"],
      Object.keys(groupErrorAlerts).map((alertName) => {
        return {
          name: alertName,
          count: Object.values(groupErrorAlerts[alertName]).length,
        };
      })
    );
    const payload = {
      alertCount: allActiveAlerts.length,
      machineAlerts,
      from,
      to,
      machineId: machine.id,
    };
    yield* put(
      type === "static"
        ? rootActions.machinePerformance.alertCountsFetched(payload)
        : rootActions.machinePerformance.alertCountsDeltaFetched(payload)
    );
  }
}
