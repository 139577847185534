/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// These options need to match those for the parser, at ../i18next-parser-config.js

export const i18nextConfig = {
  backend: {
    loadPath: "static/locales/{{lng}}/{{ns}}.i18n.json",
  },
  cleanCode: true,
  detection: {
    // Use querystring e.g. `lng=fr` to override language
    order: ["querystring", "localStorage", "navigator", "htmlTag"],
  },
  fallbackLng: "en",
  interpolation: { escapeValue: false },
  ns: ["central-web-app", "central-web-app-components"],
  returnEmptyString: false,
  // This means it'll support "de-de" as well as "de", but will make a request for "de-de" first and get a 404. https://github.com/i18next/i18next/issues/1687
  nonExplicitSupportedLngs: true,
  // We are using simple language codes here, e.g. "pt" rather than "pt-BR".
  supportedLngs: ["de", "en", "zh", "it", "es", "ja", "xx"],
};

export default i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(i18nextConfig);
