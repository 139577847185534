/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  JobAggregate,
  MachinePerfSortColumn,
  JobStatsAggregate,
} from "@/store/state";
import {
  ISOString,
  GUID,
  MachineState,
  SortDirection,
} from "@centralwebteam/narwhal";

export const machinePerformanceActions = {
  fetchingStarted: createStandardAction(
    "[machine performance] fetching data"
  )(),
  fetchingFinished: createStandardAction(
    "[machine performance] finished fetching data"
  )<{
    to: string;
  }>(),
  alertCountsFetched: createStandardAction(
    "[machine performance] alerts fetched"
  )<{
    alertCount: number;
    machineAlerts: { count: number; name: string }[];
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  statusesFetched: createStandardAction(
    "[machine performance] statuses fetched"
  )<{
    statuses: [MachineState, number][];
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  alertCountsDeltaFetched: createStandardAction(
    "[machine performance] alerts delta fetched"
  )<{
    alertCount: number;
    machineAlerts: { count: number; name: string }[];
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  statusesDeltaFetched: createStandardAction(
    "[machine performance] statuses delta fetched"
  )<{
    statuses: [MachineState, number][];
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  jobAggregateFetched: createStandardAction(
    "[machine performance] job aggregate fetched"
  )<{
    jobAggregate: JobAggregate;
    topJobStats: JobStatsAggregate[];
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  jobAggregateDeltaFetched: createStandardAction(
    "[machine performance] job aggregate delta fetched"
  )<{
    jobAggregate: JobAggregate;
    from: ISOString;
    to: ISOString;
    machineId: GUID;
  }>(),
  machineIconClicked: createStandardAction(
    "[machine performance] machine icon clicked"
  )<{ machineId: string }>(),
  headerCellClicked: createStandardAction(
    "[machine performance] header cell clicked"
  )<{
    sortColumn: MachinePerfSortColumn;
    sortDirection: SortDirection;
  }>(),
  machineRowClicked: createStandardAction(
    "[machine performance] machine row clicked"
  )<{ machineId: string }>(),
  expandAllClicked: createStandardAction(
    "[machine performance] expand all clicked"
  )<void>(),
  collapseAllClicked: createStandardAction(
    "[machine performance] collapse all clicked"
  )<void>(),
};

export type MachinePerformanceActions = ActionType<
  typeof machinePerformanceActions
>;
