/*
 * © 2023 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ACResult, rootActions } from "@/store";
import { actions } from "./actions";
import { CMSClientType } from "@/cms-api";
import { put, call, getContext } from "typed-redux-saga";
import { setNotification } from "@/store/global/thunks";
import i18n from "@/i18n";
import { getActiveLocations } from "@/presentation/Location";

let myNotificationUpdate = "",
  myNotificationUpdateSuccess = "",
  myNotificationsSettingsDeactivated = "";

i18n.then((t) => {
  myNotificationUpdate = t("message-MyNotifications Updated");
  myNotificationsSettingsDeactivated = t(
    "message-MyNotifications Settings Deactivated"
  );
});

export function* getNotificationsSaga() {
  try {
    const client: CMSClientType = yield getContext("client");
    const settings = yield* call(
      () => client.myNotifications.getSettings().promise
    );
    if (settings["Email"])
      yield* put(
        rootActions.manageNotifications.setNotificationsSettingsActivateStatus(
          true
        )
      );
    else {
      yield* put(
        rootActions.manageNotifications.setNotificationsSettingsActivateStatus(
          false
        )
      );
    }

    const notifications = yield* call(
      () => client.myNotifications.all().promise
    );
    yield* put(
      rootActions.manageNotifications.notificationsFetched(notifications)
    );
  } catch (error: any) {
    if (error?.response?.status === 404) {
      yield* put(rootActions.manageNotifications.notificationsNotFound());
    }
  }
}

export function* updateMyNotificationsSaga({ payload }: any) {
  try {
    i18n.then((t) => {
      myNotificationUpdateSuccess = t("message-MyNotifications Success", {
        destination: payload.notificationDestination,
      });
    });
    const client: CMSClientType = yield getContext("client");
    if (!payload.notificationActiveStatus) {
      yield* call(
        () => client.myNotifications.deactivateSettings("Email").promise
      );
      yield* put(
        rootActions.manageNotifications.setNotificationsSettingsActivateStatus(
          false
        )
      );

      if (payload.notifications) {
        const machineIds = payload.notifications.map((m: any) => m.machineId);
        for (const machineId of machineIds) {
          try {
            yield* call(
              (id) => client.myNotifications.delete(id).promise,
              machineId
            );
          } catch (error) {
            console.error(error);
          }
        }
      }
      yield* put(
        // @ts-ignore
        setNotification(myNotificationsSettingsDeactivated, [], "success")
      );
    } else {
      yield* call(
        () => client.myNotifications.activateSettings("Email").promise
      );

      yield* call(
        (notifications) => client.myNotifications.update(notifications).promise,
        payload.notifications
      );

      if (payload.machinesToDelete && payload.machinesToDelete.length)
        yield* call(
          (machineIds) => client.myNotifications.delete(machineIds).promise,
          payload.machinesToDelete
        );

      yield* put(
        // @ts-ignore
        setNotification(
          myNotificationUpdate,
          [myNotificationUpdateSuccess],
          "success"
        )
      );
    }
  } catch (error) {
    console.log(error);
  } finally {
    yield* call(getNotificationsSaga);
  }
}

export function toggleLocations(
  location: {
    id: string;
    name: string;
    descendants: string[];
  },
  allLocations: {
    id: string;
    name: string;
    descendants: string[];
  }[],
  activeLocations: string[]
): ACResult {
  return function (dispatch) {
    const isActive = getActiveLocations(allLocations, activeLocations).includes(
      location.id
    );
    dispatch(
      isActive
        ? actions.removeActiveLocation(location.id)
        : actions.addActiveLocation(location.id)
    );
    // if location has descendants we will need to add/remove them all
    location.descendants.forEach((id) => {
      const descendantLocation = allLocations.find((l) => l.id === id)!;
      dispatch(
        isActive
          ? actions.removeActiveLocation(descendantLocation.id)
          : actions.addActiveLocation(descendantLocation.id)
      );
    });
  };
}
