/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import {
  select,
  call,
  put,
  getContext,
  all,
  takeLatest,
  fork,
  cancel,
  take,
} from "typed-redux-saga";
import {
  selectFocusedJobId,
  selectFocusedJob,
  selectFocusedMachine,
  selectStartAndEnd,
  selectFocusedOrLatestJob,
  selectFilteredJobs,
} from "@/store/selectors";
import { RootState, rootActions } from "@/store";
import { isMetrologyMachineType } from "@centralwebteam/narwhal";
import { CMSClientType } from "@/cms-api";
import { pickAround } from "@/modules/pickAround";
import { withMeasurementData, JobPresentation } from "@/presentation/Job";
import { getGlobalConfigs } from "@/index";
import { getType } from "typesafe-actions";
import { toUTCISOString } from "@/modules/dateFormats/index";
import { fetchFiles, fetchJobMetaData, fetchJobMoreDetails } from "./shared";

export function* focusedJobContextWatcher() {
  try {
    yield* takeLatest(
      [getType(rootActions.session.mainFocusedJobFetched)],
      getFocusedJobRelatedJobs
    );
    let task = yield* fork(getFocusedJob);
    while (
      yield* take([
        getType(rootActions.machineAnalysis.jobTimelineClicked),
        getType(rootActions.jobPerformance.jobRunClicked),
        getType(rootActions.session.focusedJobIdUpdated),
        getType(rootActions.currentStatus.latestJobMarkerClicked),
        getType(rootActions.currentStatus.jobMarkerClicked),
        getType(
          rootActions.processUpdates
            .detailsTableInnerTableRowClickedSetJobDetails
        ),
      ])
    ) {
      if (task) yield* cancel(task);
      task = yield* fork(getFocusedJob);
    }
  } catch (error) {
    console.warn(`Focused job context watcher: ${error}`);
  }
}

/**
 * Flow to get the data for the focused job.
 */
function* getFocusedJob() {
  try {
    const id = yield* select(selectFocusedJobId);
    const focusedJob = yield* select(selectFocusedJob);
    const focusedMachine = yield* select(selectFocusedMachine);

    if (
      !id ||
      (focusedJob &&
        focusedJob.id === id &&
        (focusedJob.type === "metrology" || focusedJob.type === "mastering") &&
        focusedJob.measurementCharacteristics)
    ) {
      return;
    }
    const [start, end] = yield* select(selectStartAndEnd);
    const job = yield* call(fetchJobPresentationById, id!);

    if (!job) {
      yield* put(rootActions.session.focusedJobNotFound());
      return;
    } else if (job.start >= end || (job.end && job.end <= start)) {
      yield* put(rootActions.session.focusedJobNotInVisibleTimerange({ job }));
    } else if (focusedMachine && job.machineId !== focusedMachine.id) {
      yield* put(rootActions.session.focusedJobForDifferentMachine({ job }));
    }

    const machine = yield* select((state: RootState) =>
      state.global.machines.find((machine) => machine.id === job.machineId)
    );
    const moreDetails = yield* call(fetchJobMoreDetails, {
      jobId: job.id,
    });
    const filesInfoForSelectedJob: any = yield* call(fetchFiles, {
      jobId: job.id,
    });

    const metaData = yield* call(fetchJobMetaData, {
      machineId: job.machineId ?? focusedMachine?.id,
      startDate: job.start,
      endDate: job.end ?? new Date().toISOString(),
    });
    if (!machine || !isMetrologyMachineType(machine.type)) {
      // If the new focused job is non-metrology job don't fetch measurements
      yield* put(
        rootActions.session.nonMetrologyMainFocusedJobFetched({
          job,
          moreDetails,
          filesInfoForSelectedJob: filesInfoForSelectedJob,
        })
      );
    } else {
      const options = {
        from: job.start,
        to: job.end ?? new Date().toISOString(),
        machineId: job.machineId,
      };

      const measurements = yield* call(fetchMeasurementsForMachine, options);
      yield* put(
        rootActions.session.mainFocusedJobFetched({
          job: {
            ...job,
            type:
              job.type.toLowerCase() === "mastering"
                ? "mastering"
                : "metrology",
            measurementCharacteristics: measurements.data,
          },
          moreDetails:
            metaData !== undefined
              ? { ...moreDetails, ...metaData }
              : moreDetails,
          filesInfoForSelectedJob: filesInfoForSelectedJob,
        })
      );
    }
    const now = toUTCISOString(new Date());
    yield* put(
      rootActions.machineAnalysis.fetchingFinished({
        to: now,
        loadingType: "focusedJobMoreDetails",
      })
    );
  } catch (error) {
    console.warn(`Get Focused job context watcher: ${error}`);
  }
}

/**
 * Flow to get focused job (main) related jobs
 */
function* getFocusedJobRelatedJobs() {
  try {
    const client = yield* getContext<CMSClientType>("client");
    const focusedJob = yield* select(selectFocusedOrLatestJob);
    if (!focusedJob) {
      return;
    }
    const { machineId, name: jobName, start } = focusedJob;
    const jobs = yield* select(selectFilteredJobs);
    const selectedMachineSimilarJobsAsFocused = jobs.filter(
      (j: JobPresentation) =>
        j.name === focusedJob.name && j.machineId == focusedJob.machineId
    );

    let jobsBefore: any[] = [];
    let jobsAfter: any[] = [];
    let combinedJobs: any[] = [];
    // select jobs from the list when there are more than 7 jobs similar to the focused job
    if (selectedMachineSimilarJobsAsFocused.length >= 7) {
      let leftAll = selectedMachineSimilarJobsAsFocused.filter(
        (j: JobPresentation) => j.start < focusedJob.start
      );

      // check the left jobs count, if not at least three then make the endpoint calls to get left jobs for multi job table
      if (leftAll && leftAll.length < 4) {
        const eventsForLeftJobs: any[] = yield* call(
          (str) =>
            client.events.query(str).promise.then((r: any) => r.reverse()),
          `$filter=machineId eq ${machineId} and type eq 'JobStart' and name eq '${encodeURIComponent(
            jobName
          )}' and created lt ${start}&$orderby=created desc&$top=6`
        );

        if (eventsForLeftJobs?.length) {
          leftAll = yield* call(
            (str) =>
              client.jobs.summaryQuery(str).promise.then((r) => r.reverse()),
            `$filter=machineId eq ${machineId} and name eq '${encodeURIComponent(
              jobName
            )}' and start ge ${eventsForLeftJobs[0].created} and start le ${
              eventsForLeftJobs.at(-1).created
            } &$top=6`
          );
        }
      }

      let rightAll = selectedMachineSimilarJobsAsFocused.filter(
        (j: JobPresentation) => j.start > focusedJob.start
      );
      // check the right jobs count, if not at least three then make the endpoint calls to get jobs for multi job table
      if (rightAll && rightAll.length < 4) {
        const eventsForRightJobs: any = yield* call(
          (str) => client.events.query(str).promise,
          `$filter=machineId eq ${machineId} and type eq 'JobStart' and name eq '${encodeURIComponent(
            jobName
          )}' and created gt ${start}&$top=3`
        );
        if (eventsForRightJobs?.length)
          rightAll = yield* call(
            (str) =>
              client.jobs.summaryQuery(str).promise.then((r) => r.reverse()),
            `$filter=machineId eq ${machineId} and name eq '${encodeURIComponent(
              jobName
            )}' and start ge ${eventsForRightJobs[0].created} and start le ${
              eventsForRightJobs.at(-1).created
            } &$top=6`
          );
      }

      jobsBefore = leftAll.slice(-6);
      jobsAfter = rightAll.slice(0, 6);
      const [left, right] = pickAround(3, jobsBefore, jobsAfter);
      combinedJobs = left.concat(right);
    } else {
      // We fetch 6 events of type JobStart after the main focused job
      // We fetch 6 or less before events of type JobStart from main focused jobs depends on how many events do we get in after endpoint call
      // We fetch 7 jobs based on start and end date of above result
      // Why do we select 12 jobs when we only display 6?
      // For example there might only be 2 job instances that occurred after the focused job
      // in this case we would then attempt to take 4 from before so that we always show 6 related jobs in the table

      jobsAfter = focusedJob.end
        ? yield* call(
            (str) => client.events.query(str).promise,
            `$filter=machineId eq ${machineId} and type eq 'JobStart' and name eq '${encodeURIComponent(
              jobName
            )}' and created gt ${start}&$top=6`
          )
        : [];
      let topForBefore = 6;
      if (jobsAfter.length >= 3) topForBefore = 3;
      else if (jobsAfter.length === 2) topForBefore = 4;
      else if (jobsAfter.length === 1) topForBefore = 5;
      jobsBefore = yield* call(
        (str) => client.events.query(str).promise.then((r: any) => r.reverse()),
        `$filter=machineId eq ${machineId} and type eq 'JobStart' and name eq '${encodeURIComponent(
          jobName
        )}' and created lt ${start}&$orderby=created desc&$top=${topForBefore}`
      );

      const [left, right] = pickAround(3, jobsBefore, jobsAfter);
      const combined: any = left.concat(right);

      if (combined.length) {
        combinedJobs = yield* call(
          (str) =>
            client.jobs.summaryQuery(str).promise.then((r) => r.reverse()),
          `$filter=machineId eq ${machineId} and name eq '${encodeURIComponent(
            jobName
          )}' and start ge ${combined[0].created} and start le ${
            combined.at(-1).created
          } &$top=7`
        );
      }
    }

    if (combinedJobs.length) {
      // Load measurements for jobs
      const jobMeasurements: any = yield* all(
        combinedJobs.map((job: JobPresentation) =>
          call(fetchMeasurementsForMachine, {
            from: job.start,
            to: job.end || new Date().toISOString(),
            machineId: job.machineId,
          })
        )
      );
      yield* all(
        jobMeasurements.map((measurements: any, index: number) => {
          // index matches
          return put(
            rootActions.session.relatedFocusedJobFetched({
              job: withMeasurementData(
                combinedJobs[index] as JobPresentation,
                measurements.data
              ),
            })
          );
        })
      );
    }
  } catch (error) {
    console.warn(`Get Focused job related job context watcher: ${error}`);
  }
}

function* fetchJobPresentationById(id: string) {
  const client = yield* getContext<CMSClientType>("client");
  try {
    const [job] = yield* call(
      (id) => client.jobs.summary({ query: { id } }).promise,
      id
    );
    return job as JobPresentation | undefined;
  } catch (error) {
    console.error(error);
  }
}

function* fetchMeasurementsForMachine({
  from,
  to,
  machineId,
}: {
  from: string;
  to: string;
  machineId: string;
}) {
  const config = yield* call(() => getGlobalConfigs());
  const client = yield* getContext<CMSClientType>("client");
  const measurements = yield* call(
    (query) => client.events.measurementCharacteristics.all(query).promise,
    { from, to, take: config.maxTake, machineId }
  );
  return { from, to, data: measurements };
}
