/*
 * © 2023 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { deprecated, ActionType } from "typesafe-actions";
import { MyNotification } from "@centralwebteam/narwhal";
const { createStandardAction } = deprecated;
export const actions = {
  notificationsFetched: createStandardAction(
    "[manage notifications] notifications fetched"
  )<MyNotification[]>(),
  notificationsNotFound: createStandardAction(
    "[manage notifications] notifications not found"
  )(),
  activateOrDeactivateNotificationsToggleButtonClicked: createStandardAction(
    "[manage notifications] activate or deactivate notifications toggle button clicked"
  )<boolean>(),
  setNotificationsSettingsActivateStatus: createStandardAction(
    "[manage notifications] set notifications settings activate status"
  )<boolean>(),
  sectionToggled: createStandardAction(
    "[manage notifications] toggle section expansion"
  )<string>(),
  setToggledLocationIds: createStandardAction(
    "[manage notifications] toggle locations expansion"
  )<string>(),
  setActiveMachineAlerts: createStandardAction(
    "[manage notifications] set active machine alerts"
  )<string[]>(),
  setActiveMachineStatuses: createStandardAction(
    "[manage notifications] set active machine statuses"
  )<string[]>(),
  setActiveJobStatuses: createStandardAction(
    "[manage notifications] set active job statuses"
  )<string[]>(),
  setActiveJobVerdicts: createStandardAction(
    "[manage notifications] set active job verdicts"
  )<string[]>(),
  addActiveLocation: createStandardAction(
    "[manage notifications] add active location"
  )<string>(),
  removeActiveLocation: createStandardAction(
    "[manage notifications] remove active location"
  )<string>(),
  setActiveMachines: createStandardAction(
    "[manage notifications] set active machine"
  )<string>(),
  saveButtonClicked: createStandardAction(
    "[manage notifications] save button clicked"
  )<{
    notifications: MyNotification[];
    notificationDestination: string;
    notificationActiveStatus: boolean;
    machinesToDelete?: string[];
  }>(),
  selectAllClicked: createStandardAction(
    "[manage notifications] select all clicked"
  )(),
  clearAllClicked: createStandardAction(
    "[manage notifications] clear all clicked"
  )(),
  resetClicked: createStandardAction("[manage notifications] reset clicked")(),
  toggleManageDevicesClicked: createStandardAction(
    "[manage notifications] toggle manage devices clicked"
  )<boolean>(),
  settingHeadingCheckboxClicked: createStandardAction(
    "[manage notifications] settings heading checkbox clicked"
  )<string[]>(),
};

export type Actions = ActionType<typeof actions>;
