/*
 * © 2023 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getNotificationsSaga, updateMyNotificationsSaga } from "./saga";
import { rootActions } from "@/store";
import { getType } from "typesafe-actions";
import { all, takeLatest } from "typed-redux-saga";
export function* rootSaga() {
  yield* getNotificationsSaga();
  yield* all([
    takeLatest(
      getType(rootActions.manageNotifications.saveButtonClicked),
      updateMyNotificationsSaga
    ),
  ]);
}
