/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
/* eslint no-fallthrough: 0 */ // there is a bug with eslint and nested switch statements.
import React from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Provider, useDispatch } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { useTranslation } from "react-i18next";
import { store, persistor, useTypedSelector, rootActions } from "@/store";
// styling
import { GlobalStyle } from "./GlobalStyle";
import { ThemeProvider, styled, useTheme } from "@/styled-components";
// components
import { Notification } from "@/App/Notification";
import {
  mapRouteNameToComponent,
  publicRoutes,
} from "@/store/session/routeDefinitions";
import {
  selectCurrentRoute,
  selectIsAuthenticated,
  selectIsAuthenticating,
  selectStartupState,
  selectUsername,
  selectEnableIPC,
} from "@/store/selectors";
import Login from "@/handlers/Login";
import {
  ErrorBoundary,
  AppShell,
  Heading,
  Gutter,
  AppShellNavigationItem,
  Busy,
  LicenceNotFound,
  EgretLicenceServerError,
  ConnectionFailedToCentralServer,
  Button,
  Flex,
  FlexSpace,
  Subtle,
  CentralServerError,
} from "@centralwebteam/jellyfish";
import { assertUnreachable } from "@/@types/assertUnreachable";
import { Config, getBuildConfigs, getBaseURL } from "@/index";
import { isSupported } from "../browserSupport";
import { detect } from "detect-browser";
type Props = {
  config: Config;
};

const LicenceHeading = styled(Subtle)`
  font-size: 24px;
`;
const App: React.FC<Props> = ({ config }) => {
  return (
    <React.Suspense
      fallback={
        /* Do not translate, this fallback is triggered when the translation system is not ready */
        <div style={{ margin: 15 }}>Loading...</div>
      }
    >
      <DndProvider backend={HTML5Backend}>
        <Provider store={store}>
          {/* @ts-ignore redux-persist is getting old, but still works */}
          <PersistGate persistor={persistor}>
            <ThemeProvider theme={config.theme}>
              <GlobalStyle />
              <ErrorBoundary>
                <ConfigStore config={config} />
                <LicenceGuard config={config} />
              </ErrorBoundary>
            </ThemeProvider>
          </PersistGate>
        </Provider>
      </DndProvider>
    </React.Suspense>
  );
};

/** An effect component which simply sends a `configLoaded` action. This has to happen in a component inside a Provider. */
const ConfigStore: React.FC<Props> = ({ config }) => {
  const dispatch = useDispatch();
  dispatch(rootActions.global.configLoaded(config));
  return null;
};

const LicenceGuard: React.FC<Props> = ({ config }) => {
  const browser = detect();
  const os = browser?.os;
  const isOperatingSystemNotSupported = os?.includes("iOS") ? true : false;
  const { t } = useTranslation();
  const theme = useTheme();
  const manageServer = useTypedSelector((state) => state.manageServer);
  const serverLicenceState = manageServer.serverLicenceState;
  const authenticated = useTypedSelector(selectIsAuthenticated);
  const authenticating = useTypedSelector(selectIsAuthenticating);
  const ipc = useTypedSelector(selectEnableIPC);
  const route = useTypedSelector(selectCurrentRoute);
  const server = JSON.parse(localStorage.getItem("system")!);

  const dispatch = useDispatch();
  const buildConfig = getBuildConfigs();
  let pages = useTypedSelector((s) => s.global.preferences?.pages);

  // handle invalid pages data, should have a string array as pages
  if (
    pages !== undefined &&
    pages.filter((f) => typeof f !== "string").length > 0
  )
    pages = undefined;

  const preferencesNavigationItems: (AppShellNavigationItem | undefined)[] =
    React.useMemo(() => {
      try {
        return [
          pages === undefined ||
          (pages &&
            pages.length !== 0 &&
            pages!.some((p: string) => p.toLowerCase() === "current status"))
            ? {
                display: t("heading-Current Status"),
                mode: route === "current status" ? "active" : "normal",
                onClick: () =>
                  void dispatch(
                    rootActions.global.currentStatusHeaderLinkClicked()
                  ),
              }
            : undefined,
          pages === undefined ||
          (pages &&
            pages.length !== 0 &&
            pages!.some((p: string) => p.toLowerCase() === "job performance"))
            ? {
                display: t("heading-Job Performance"),
                mode: route === "job performance" ? "active" : "normal",
                onClick: () =>
                  void dispatch(
                    rootActions.global.jobPerformanceHeaderLinkClicked()
                  ),
              }
            : undefined,
          pages === undefined ||
          (pages &&
            pages.length !== 0 &&
            pages!.some(
              (p: string) => p.toLowerCase() === "machine performance"
            ))
            ? {
                display: t("heading-Machine Performance"),
                mode: route === "machine performance" ? "active" : "normal",
                onClick: () =>
                  void dispatch(
                    rootActions.global.machinePerformanceHeaderLinkClicked()
                  ),
              }
            : undefined,
        ];
      } catch (e) {
        console.log(e);
        return [];
      }
    }, [route, dispatch, pages, t]);

  const navigationItems: (AppShellNavigationItem | undefined)[] = React.useMemo(
    () =>
      authenticated && serverLicenceState === "licensed"
        ? [
            ...preferencesNavigationItems,
            {
              display: t`heading-Machine Analysis`,
              mode: route === "machine analysis" ? "active" : "normal",
              onClick: () =>
                void dispatch(
                  rootActions.global.machineAnalysisHeaderLinkClicked()
                ),
            },
            {
              display: t`heading-Process Updates`,
              mode: route === "process updates" ? "active" : "normal",
              onClick: () =>
                void dispatch(
                  rootActions.global.processUpdatesHeaderLinkClicked()
                ),
            },
            ipc
              ? {
                  display: t`heading-IPC`,
                  mode: "normal",
                  onClick: () => (window.location.href = "ipc/#!"),
                }
              : undefined,
          ]
        : [],
    [
      authenticated,
      serverLicenceState,
      route,
      dispatch,
      ipc,
      preferencesNavigationItems,
      t,
    ]
  );
  const username = useTypedSelector(selectUsername);
  const startupState = useTypedSelector(selectStartupState);
  const bodyElements = React.useMemo(() => {
    if (manageServer.showFooter && !authenticated) {
      return (
        <Flex
          col
          style={{
            justifyContent: "center",
            alignItems: "center",
            background: theme.panel,
          }}
        >
          <CentralServerError
            title={t("message-issueForRenishawCentralServer")}
          />
          <FlexSpace size={10} />
          <LicenceHeading opacity={0.6}>
            {t("message-issueForRenishawCentralServer")}
          </LicenceHeading>
          <FlexSpace size={5} />
          <Subtle opacity={0.6} style={{ fontSize: "18px" }}>
            {t("message-issueForRenishawCentralServerRetry")}
          </Subtle>
          <FlexSpace size={15} />
          <Button
            style={{ width: "100px" }}
            height={28}
            title={t`button-retry`}
            onClick={() =>
              void dispatch(
                rootActions.global.licenceServiceRefreshButtonClicked()
              )
            }
          >
            {t("button-retry")}
          </Button>
        </Flex>
      );
    } else {
      switch (startupState) {
        case "started": {
          return null;
        }
        case "complete": {
          switch (authenticated) {
            case true: {
              switch (serverLicenceState) {
                case "LicenceServiceNotRunning": {
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <EgretLicenceServerError
                        title={manageServer.licenceErrorMessage}
                      />
                      <FlexSpace />
                      <LicenceHeading>
                        {manageServer.licenceErrorMessage}
                      </LicenceHeading>
                      <FlexSpace />
                      <Subtle>{manageServer.licenceRetryErrorMessage}</Subtle>
                      <FlexSpace />
                      <Button
                        title={t`button-retry`}
                        onClick={() =>
                          void dispatch(
                            rootActions.global.licenceServiceRefreshButtonClicked()
                          )
                        }
                      >
                        {t("button-retry")}
                      </Button>
                    </Flex>
                  );
                }
                case "UnlicensedServiceRunning":
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <LicenceNotFound
                        title={manageServer.licenceErrorMessage}
                      />
                      <FlexSpace />
                      <LicenceHeading>
                        {manageServer.licenceErrorMessage}
                      </LicenceHeading>
                      <FlexSpace />
                      <Subtle> {manageServer.licenceRetryErrorMessage}</Subtle>
                      <FlexSpace />
                      <Button
                        title={t`button-refreshLicence`}
                        onClick={() =>
                          void dispatch(
                            rootActions.global.licenceServiceRefreshButtonClicked()
                          )
                        }
                      >
                        {t("button-refreshLicence")}
                      </Button>
                    </Flex>
                  );
                case "ConnectionFailedToCentralServer":
                  return (
                    !authenticated && (
                      <Flex
                        col
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          background: theme.panel,
                        }}
                      >
                        <ConnectionFailedToCentralServer
                          title={manageServer.licenceErrorMessage}
                        />
                        <FlexSpace size={10} />
                        <LicenceHeading opacity={0.6}>
                          {manageServer.licenceErrorMessage}
                        </LicenceHeading>
                        <FlexSpace size={5} />
                        <Subtle opacity={0.6} style={{ fontSize: "18px" }}>
                          {manageServer.licenceRetryErrorMessage}
                        </Subtle>
                        <FlexSpace size={15} />
                        <Button
                          style={{ width: "100px" }}
                          height={28}
                          title={t`button-retry`}
                          onClick={() =>
                            void dispatch(
                              rootActions.global.licenceServiceRefreshButtonClicked()
                            )
                          }
                        >
                          {t("button-retry")}
                        </Button>
                      </Flex>
                    )
                  );
                case "unlicensed": {
                  return (
                    <>
                      <Notification />
                      <Gutter>
                        <Heading>{t`message-serverNotLicensed`}</Heading>
                      </Gutter>
                    </>
                  );
                }
                case "unknown": {
                  return (
                    <Gutter>
                      <Busy show message={t`message-loadingPage`} />
                    </Gutter>
                  );
                }
                case "licensed": {
                  return (
                    <>
                      <Notification />
                      <React.Suspense fallback={null}>
                        <SwitchCurrentView />
                      </React.Suspense>
                    </>
                  );
                }
                default: {
                  assertUnreachable(serverLicenceState);
                }
              }
            }
            // Not authenticated
            case false: {
              if (publicRoutes.includes(route))
                return (
                  <>
                    <Notification />
                    <div style={{ background: theme.panel, height: "100%" }}>
                      <React.Suspense fallback={null}>
                        <SwitchCurrentView />
                      </React.Suspense>
                    </div>
                  </>
                );
              else {
                if (serverLicenceState === "LicenceServiceNotRunning") {
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <EgretLicenceServerError
                        title={manageServer.licenceErrorMessage}
                      />
                      <FlexSpace />
                      <LicenceHeading>
                        {manageServer.licenceErrorMessage}
                      </LicenceHeading>
                      <FlexSpace />
                      <Subtle> {manageServer.licenceRetryErrorMessage}</Subtle>
                      <FlexSpace />
                      <Button
                        title={t("button-retry")}
                        onClick={() =>
                          void dispatch(
                            rootActions.global.licenceServiceRefreshButtonClicked()
                          )
                        }
                      >
                        {t("button-retry")}
                      </Button>
                    </Flex>
                  );
                }
                if (serverLicenceState === "UnlicensedServiceRunning") {
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <LicenceNotFound
                        title={manageServer.licenceErrorMessage}
                      />
                      <FlexSpace />
                      <LicenceHeading>
                        {manageServer.licenceErrorMessage}
                      </LicenceHeading>
                      <FlexSpace />
                      <Subtle> {manageServer.licenceRetryErrorMessage}</Subtle>
                      <FlexSpace />
                      <Button
                        title={t`button-refreshLicence`}
                        onClick={() =>
                          void dispatch(
                            rootActions.global.licenceServiceRefreshButtonClicked()
                          )
                        }
                      >
                        {t("button-refreshLicence")}
                      </Button>
                    </Flex>
                  );
                }
                if (serverLicenceState === "ConnectionFailedToCentralServer")
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <ConnectionFailedToCentralServer
                        title={manageServer.licenceErrorMessage}
                      />
                      <FlexSpace size={10} />
                      <LicenceHeading opacity={0.6}>
                        {manageServer.licenceErrorMessage}
                      </LicenceHeading>
                      <FlexSpace size={5} />
                      <Subtle opacity={0.6} style={{ fontSize: "18px" }}>
                        {manageServer.licenceRetryErrorMessage}
                      </Subtle>
                      <FlexSpace size={15} />
                      <Button
                        style={{ width: "100px" }}
                        height={28}
                        title={t`button-retry`}
                        onClick={() =>
                          void dispatch(
                            rootActions.global.licenceServiceRefreshButtonClicked()
                          )
                        }
                      >
                        {t("button-retry")}
                      </Button>
                    </Flex>
                  );
                if (serverLicenceState === "licensed")
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <Notification />
                      <Login />
                    </Flex>
                  );
                if (serverLicenceState === "unknown")
                  return (
                    <Flex
                      col
                      style={{
                        justifyContent: "center",
                        alignItems: "center",
                        background: theme.panel,
                      }}
                    >
                      <Notification />
                    </Flex>
                  );
                // serverLicenceState is "unlicensed"
                return (
                  <>
                    <Notification />
                    <Gutter>
                      <Heading>{t`message-serverNotLicensed`}</Heading>
                    </Gutter>
                  </>
                );
              }
            }
          }
        }
        default: {
          assertUnreachable(startupState);
        }
      }
    }
  }, [
    manageServer,
    authenticated,
    route,
    serverLicenceState,
    startupState,
    t,
    dispatch,
    theme,
  ]);
  const provisioningId = localStorage.getItem("provisioningId")!;

  let apiUrl: string = getBaseURL();
  // If the API URL is relative, prepend this page's origin
  if (!/^https?:/i.test(apiUrl)) {
    apiUrl = `${location.origin}${apiUrl}`;
  }

  return (
    <AppShell
      showBanner={config.globalConfigs.showBanner}
      header={{
        logoType: server?.type,
        logoClicked: () => {
          let routeToRedirect = "#!/manage/myaccount";
          if (pages?.includes("Current Status"))
            routeToRedirect = "#!/current-status";
          else if (pages?.includes("Job Performance"))
            routeToRedirect = "#!/job-performance";
          else if (pages?.includes("Machine Performance"))
            routeToRedirect = "#!/machine-performance";

          if (pages === undefined) routeToRedirect = "#!/current-status";
          return (window.location.href = routeToRedirect);
        },
      }}
      navigation={{
        apiUrl,
        provisioningId,
        navigationItems,
        managementMode: route?.startsWith("manage") ? "active" : "normal",
        onManagementIconClicked: () =>
          void dispatch(rootActions.global.manageCogHeaderLinkClicked()),
        authenticated,
        authenticating,
        username,
        onSignOutLinkClicked: () =>
          void dispatch(rootActions.session.headerSignOutLinkClicked()),
        signOutText: t`button-Sign out`,
        versionPopup: {
          buildInfo: buildConfig,
          emptyMessage: t`message-systemInfoRefresh`,
        },
      }}
      showFooter={(manageServer.showFooter && authenticated) || !isSupported}
      isBrowserSupported={isSupported}
      isOperatingSystemNotSupported={isOperatingSystemNotSupported}
      showCloseButton={true}
    >
      {bodyElements}
    </AppShell>
  );
};

const SwitchCurrentView = React.memo(() => {
  const route = useTypedSelector(selectCurrentRoute);
  const Component = mapRouteNameToComponent[route];
  const pages = useTypedSelector((s) => s.global.preferences?.pages);
  if (
    route === "job performance" ||
    route === "machine performance" ||
    route === "current status"
  ) {
    if (
      pages === undefined ||
      (pages && pages.filter((f) => typeof f !== "string").length > 0) ||
      (pages &&
        pages.length &&
        pages!.some((p: string) => p.toLowerCase() === route))
    ) {
      return <Component />;
    } else {
      if (pages?.includes("Current Status"))
        window.location.href = "#!/current-status";
      else if (pages?.includes("Job Performance"))
        window.location.href = "#!/job-performance";
      else if (pages?.includes("Machine Performance"))
        window.location.href = "#!/machine-performance";
      else window.location.href = "#!/manage/myaccount";
      return null;
    }
  } else {
    return <Component />;
  }
});

export default App;
