/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { ActionType, deprecated } from "typesafe-actions";
const { createStandardAction } = deprecated;
import {
  SystemPermissions,
  AuthResponse,
  FileInfoForSelectedJob,
  EntityPermission,
} from "@centralwebteam/narwhal";
import { Routes } from "./routeDefinitions";
import { Location } from "history";
import { JobPresentation } from "@/presentation/Job";
import { ViewState } from "@centralwebteam/jellyfish/src/Login/types";

export type UserDetails = {
  username: string;
  password?: string;
};

export const actions = {
  // auth
  userLoginSubmitted: createStandardAction("[session] user login submitted")<{
    username: string;
    password: string;
  }>(),
  loginStateChanged: createStandardAction("[session] login state changed")<{
    state: ViewState;
  }>(),
  userIsLoggedInAppStart: createStandardAction(
    "[session] (app start) user is logged in"
  )<{
    username: string;
    permissions: SystemPermissions[];
    view: Routes;
  }>(),
  userIsLoggedOutAppStart: createStandardAction(
    "[session] (app start) user is logged out"
  )<string>(),
  userLoggedIn: createStandardAction("[session] user logged in")<{
    username: string;
    permissions: SystemPermissions[];
  }>(),
  userFailedToLogIn: createStandardAction("[session] user failed to log in")(),
  setUserPermission: createStandardAction("[session] set user permission")<{
    username: string;
    permissions: SystemPermissions[];
    locationEntityPermissions: EntityPermission[];
  }>(),
  userSessionRefreshed: createStandardAction(
    "[session] user session refreshed"
  )<AuthResponse>(),
  userLoggedOut: createStandardAction("[session] user logged out")(),
  headerSignOutLinkClicked: createStandardAction(
    "[session] header sign out link clicked"
  )(),
  axiosInterceptorTokenCallFailed: createStandardAction(
    "[session/axios] token call failed"
  )(),
  axiosInterceptorFailedToRefreshToken: createStandardAction(
    "[session/axios] failed to refresh token"
  )(),
  userRequestedPasswordResetLink: createStandardAction(
    "[session] user requested password reset link"
  )<{
    email: string;
    translationTexts: {
      resetSent: string;
      resetSentEmail: string;
      resetWritten: string;
      resetWrittenToDisk: string;
      resetFailed: string;
    };
  }>(),
  userRequestedPasswordReset: createStandardAction(
    "[session] user requested password reset"
  )<{
    newPassword: string;
    resetToken: string;
  }>(),
  userPasswordReset: createStandardAction("[session] user password reset")(),
  userPasswordFailedToReset: createStandardAction(
    "[session] user password failed to reset"
  )(),
  // route
  routeChanged: createStandardAction("[session] route changed")<Location>(),
  startEndUpdated: createStandardAction("[session] start end updated")<
    [string, string | null]
  >(),
  umrtUpdated: createStandardAction("[session] umrt updated")<string | null>(),
  languageUpdated: createStandardAction("[session] language updated")<
    string | null
  >(),
  focusedMachineIdUpdated: createStandardAction(
    "[session] focused machine id updated"
  )<string | null>(),
  focusedJobIdUpdated: createStandardAction("[session] focused job id updated")<
    string | null
  >(),
  userToggledDateMode: createStandardAction(
    "[session] user toggled date mode"
  )<string>(),
  passwordResetTokenUpdated: createStandardAction(
    "[session] password reset token updated"
  )<string>(),
  resetPasswordReturnLinkClicked: createStandardAction(
    "[reset password] reset password return link clicked"
  )(),
  passwordResetSuccessfully: createStandardAction(
    "[session] password reset successfully"
  )(),
  forgotPasswordButtonClicked: createStandardAction(
    "[login form] forgotPasswordButtonClicked"
  )(),
  // focused job context loading
  mainFocusedJobFetched: createStandardAction(
    "[session][saga] main focused job fetched"
  )<{
    job: JobPresentation;
    moreDetails: any;
    filesInfoForSelectedJob: FileInfoForSelectedJob[];
  }>(),
  nonMetrologyMainFocusedJobFetched: createStandardAction(
    "[session][saga] non metrology main focused job fetched"
  )<{
    job: JobPresentation;
    moreDetails: any;
    filesInfoForSelectedJob: FileInfoForSelectedJob[];
  }>(),
  relatedFocusedJobFetched: createStandardAction(
    "[session][saga] related focused job fetched"
  )<{ job: JobPresentation }>(),
  focusedJobNotFound: createStandardAction(
    "[session][saga] focused job not found"
  )(),
  focusedJobForDifferentMachine: createStandardAction(
    "[session][saga] focused job for different machine"
  )<{ job: JobPresentation }>(),
  focusedJobNotInVisibleTimerange: createStandardAction(
    "[session][saga] focused job not in visible timerange"
  )<{ job: JobPresentation }>(),
  manageHeaderAssetsLinkClicked: createStandardAction(
    "[manage] manage header Assets link clicked"
  )(),
  manageHeaderNotificationsLinkClicked: createStandardAction(
    "[manage] manage header notifications link clicked"
  )(),
  manageHeaderProvisioningLinkClicked: createStandardAction(
    "[manage] manage header Provisioning link clicked"
  )(),
  manageHeaderUsersLinkClicked: createStandardAction(
    "[manage] manage header Users link clicked"
  )(),
  manageHeaderMyAccountLinkClicked: createStandardAction(
    "[manage] manage header MyAccount link clicked"
  )(),
  manageHeaderClientsLinkClicked: createStandardAction(
    "[manage] manage header Clients link clicked"
  )(),
  // licence
  serverNotLicensed: createStandardAction("[session] server not licensed")(),
  licenceServiceNotRunning: createStandardAction(
    "[session] licence service is not running"
  )<{ message: string; retryMessage: string }>(),
  unLicencedServiceRunning: createStandardAction(
    "[session] Unlicenced service is running"
  )<{ message: string; retryMessage: string }>(),
  connectionFailedToCentralServer: createStandardAction(
    "[session] connection failed to central server"
  )<{ message: string; retryMessage: string }>(),
  // to handle response status code
  showFooter: createStandardAction(
    "[session] display footer with error message"
  )<{ showFooter: boolean }>(),
  connectionToServerIsNotDefined: createStandardAction(
    "[session] connection to server is not defined"
  )(),
  serverIsLicensed: createStandardAction("[session] server is licensed")(),
  serverLicenceChecked: createStandardAction(
    "[session] got server licence details"
  )<{
    daysRemaining?: string;
    environment?: string;
    status?: string;
    type?: string;
  }>(),
};

export type Actions = ActionType<typeof actions>;
