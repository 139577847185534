/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getContext } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { orderBy } from "lodash";
import { CMSClientType } from "@/cms-api";
import {
  Alert,
  GUID,
  ISOString,
  MachineStatus,
  ProcessAction,
  TimeSeries,
  UniqueMeasurementType,
  UnitDisplayHint,
} from "@centralwebteam/narwhal";
import { getGlobalConfigs } from "@/index";
import { AlertPresentation } from "@/presentation/Alert";
import {
  createUniqueMeasurementID,
  MeasurementCharacteristicPresentation,
} from "@/presentation/MeasurementCharacteristic";
import { JobPresentation } from "@/presentation/Job";

/** Change the first character of a string to lower-case */
const lowerCaseFirst = (s: string | undefined) =>
  s ? s[0].toLowerCase() + s.substring(1) : undefined;

export const data = {
  fetchTimeSeriesLimits: function* fetchTimeSeriesLimits(query: {
    from: string;
    to: string;
    id: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    try {
      const timeSeriesLimits = yield* call(
        (query) =>
          client.timeSeries.limits({
            query: {
              ...query,
              timeSeriesId: query.id,
              take: config.maxTake,
            },
          }).promise,
        query
      );
      return {
        id: query.id,
        from: query.from,
        to: query.to,
        data: timeSeriesLimits,
      };
    } catch (ex) {
      console.warn(ex);
      return { ...query, data: [] };
    }
  },
  fetchUniqueTimeSeries: function* fetchUniqueTimeSeries(query: {
    from: string;
    to: string;
    machineId: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let timeSeriesTypes = [] as TimeSeries[];
    try {
      timeSeriesTypes = yield* call(
        (query) =>
          client.timeSeries.active({
            query: { ...query, take: config.maxTake },
          }).promise,
        query
      );
    } catch (ex) {
      console.warn(ex);
    }
    yield* call(
      (types: typeof timeSeriesTypes) =>
        types
          .map((t) => ({
            ...t,
            unitType: lowerCaseFirst(t.unitType),
          }))
          .sort((a, b) =>
            (a.grouping ?? "") + a.name < (b.grouping ?? "") + b.name ? -1 : 1
          ),
      timeSeriesTypes
    );
    return timeSeriesTypes;
  },
  fetchTimeSeriesValues: function* fetchTimeSeriesValues(query: {
    from: string;
    to: string;
    id: string;
    sample?: "Raw" | "Hour" | "Day" | "Week" | "Month" | "Default" | undefined;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    try {
      const timeSeriesValues = yield* call(
        (query) =>
          client.timeSeries.compactValues({
            query: {
              ...query,
              timeSeriesId: query.id,
              take: config.maxTake,
              sample: (query.sample ?? "Default") as
                | "Default"
                | "Raw"
                | "Hour"
                | "Day"
                | "Week"
                | "Month",
            },
          }).promise,
        query
      );
      // weird data structure used by the api
      return {
        ...query,
        data: Object.values(timeSeriesValues)[0] ?? [],
      };
    } catch (ex) {
      console.warn(ex);
      return { ...query, data: [] };
    }
  },
  fetchMeasurementValues: function* fetchMeasurementValues(query: {
    from: string;
    to: string;
    machineId: string;
    featureName?: string;
    name: string;
    jobName?: string[];
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let measurements = [] as MeasurementCharacteristicPresentation[];
    try {
      measurements = yield* call(
        (query) =>
          client.events.measurementCharacteristics.all({
            ...query,
            take: config.maxTake,
          }).promise,
        query
      );
    } catch (ex) {
      console.warn(ex);
    }
    return {
      from: query.from,
      to: query.to,
      machineId: query.machineId,
      name: query.name,
      featureName: query.featureName,
      data: measurements,
    };
  },
  /**
   * Fetches unique measurement types that occured during timerange.
   * Also filtered by job type (name).
   */
  fetchUniqueMeasurementsTypes: function* fetchUniqueMeasurementsTypes({
    from,
    to,
    machineId,
    jobs,
  }: {
    from: string;
    to: string;
    machineId: string;
    jobs: string[];
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let uniqueMeasurements = [] as UniqueMeasurementType[];
    try {
      uniqueMeasurements = yield* call(
        (query) =>
          client.events.measurementCharacteristics.unique(query).promise,
        { from, to, machineId, jobs, take: config.maxTake }
      );
    } catch (ex) {
      console.warn(ex);
    }
    return yield* call(
      (measurements: typeof uniqueMeasurements) =>
        measurements.sort((a, b) =>
          createUniqueMeasurementID(a) < createUniqueMeasurementID(b) ? -1 : 1
        ),
      uniqueMeasurements
    );
  },

  /**
   * Wraps api client to return requested job summaries.
   */
  fetchJobSummaries: function* fetchJobSummaries({
    from,
    to,
    machineId,
  }: {
    from: ISOString;
    to?: ISOString;
    machineId: GUID;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let jobSummaries = [] as JobPresentation[];
    try {
      jobSummaries = yield* call(
        (query) =>
          client.jobs.summary(query).promise.then((res) => res.reverse()),
        {
          query: { machineId, from, to, take: config.maxTake },
        }
      );
    } catch (ex) {
      console.warn(ex);
    }
    return jobSummaries;
  },

  /**
   * Wraps api client to return "DisplayHintUnit" jobProperty events.
   * See also src/store/sagas/focusedJob.ts, which takes only the latest.
   */
  fetchUnitHints: function* fetchUnitHints({
    from,
    to,
    machineId,
  }: {
    from: string;
    to: string;
    machineId: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let unitHints = [] as UnitDisplayHint[];
    try {
      unitHints = yield* call(
        (query) => client.events.jobProperties.displayHintUnits(query).promise,
        {
          query: {
            machineId,
            from,
            to,
            take: config.maxTake,
          },
        }
      );
      if (unitHints.length) {
        // Make sure values start with lower-case letters
        unitHints = orderBy(unitHints, "created").map((h) => {
          return {
            ...h,
            value: Object.entries(h.value).reduce((o, e) => {
              return {
                ...o,
                [lowerCaseFirst(e[0])!]: e[1],
              };
            }, {}),
          };
        });
      }
    } catch (ex) {
      console.warn(ex);
    }
    return unitHints;
  },

  /**
   * Wraps api client to return requested machine states.
   */
  fetchMachineStates: function* fetchMachineStates({
    from,
    to,
    machineId,
  }: {
    from: string;
    to: string;
    machineId: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let statuses = [] as MachineStatus[];
    try {
      statuses = yield* call(
        (options) =>
          client.events.machinestatus.all(options).promise.then((r) => r),
        {
          query: {
            machineId,
            from,
            to,
            take: config.maxTake,
          },
        }
      );
    } catch (ex) {
      console.warn(ex);
    }
    return statuses;
  },
  /**
   * Wraps api client to return requested alerts (event)
   */
  fetchAlerts: function* fetchAlerts({
    from,
    to,
    machineId,
  }: {
    from: string;
    to: string;
    machineId: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let alerts = [] as Alert[];
    try {
      alerts = yield* call(
        (from, to, take, machineId) =>
          client.events.alerts.all({ query: { from, to, take, machineId } })
            .promise,
        from,
        to,
        config.maxTake,
        machineId
      );
    } catch (ex) {
      console.warn(ex);
    }

    return yield* call(
      (a: typeof alerts) => a.map((alert) => new AlertPresentation(alert)),
      alerts
    );
  },
  /**
   * Wraps api client to return requested ProcessActions (Mastering and Tool Offset)
   */
  fetchProcessActions: function* fetchProcessActions({
    from,
    to,
    machineId,
  }: {
    from: string;
    to: string;
    machineId: string;
  }) {
    const config = yield* call(() => getGlobalConfigs());
    const client: CMSClientType = yield getContext("client");
    let events = [] as ProcessAction[];
    try {
      events = yield* call(
        (from, to, take, machineId) =>
          client.events.processActions.all({
            query: { from, to, take, machineId },
          }).promise,
        from,
        to,
        config.maxTake,
        machineId
      );
    } catch (ex) {
      console.warn(ex);
    }

    return events;
  },
};
