/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { getContext } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { CMSClientType } from "@/cms-api";
import {
  OffsetAdjustmentApplied,
  OffsetAdjustmentCalculation,
} from "@centralwebteam/narwhal";

export const data = {
  fetchOffsetAdjustment: function* fetchOffsetAdjustment({
    from,
    to,
    take,
  }: {
    from: string;
    to: string;
    take: number;
  }) {
    const client: CMSClientType = yield getContext("client");
    const offsetAdjustmentApplied: OffsetAdjustmentApplied[] = yield* call(
      (from, to) =>
        client.events.offsetAdjustmentApplied.all(from, to, take).promise,
      from,
      to
    );
    const offsetAdjustmentCalculation: OffsetAdjustmentCalculation[] =
      yield* call(
        (from, to) =>
          client.events.offsetAdjustmentCalculation.all(from, to, take).promise,
        from,
        to
      );
    const merged = [...offsetAdjustmentApplied, ...offsetAdjustmentCalculation];
    return merged;
  },
};
