/*
 * © 2017 Renishaw plc. All rights reserved.
 * This source file is the confidential property and copyright of Renishaw plc
 * Reproduction or transmission in whole or in part, in any form or
 * by any means, electronic, mechanical or otherwise, is prohibited
 * without the prior written consent of the copyright owner.
 */
import { assertUnreachable } from "@/@types/assertUnreachable";
import {
  coreDataLoopSaga,
  getCoreDataSaga,
  dataLoadingPerformanceNotificationWatcher,
} from "./global/sagas";
import { getType } from "typesafe-actions";
import { jobPerformanceRootSaga } from "./jobPerformance/rootSaga";
import { manageMyAccountRootSaga } from "./manageMyAccount/rootSaga";
import { rootSaga as manageDeviceRootSaga } from "./manageMachineProvisioning/sagas";
import { rootActions } from ".";
import { machineAnalysisRootSaga } from "./machineAnalysis/rootSaga";
import { rootSaga as currentStatusRootSaga } from "./currentStatus/rootSaga";
import { rootSaga as manageAssetsRootSaga } from "./manageAssets/sagas";
import { rootSaga as manageClientsRootSaga } from "./manageClients/saga";
import { rootSaga as manageNotificationsRootSaga } from "./manageNotifications/rootSaga";
import { Routes } from "./session/routeDefinitions";
import {
  selectCurrentRoute,
  selectIsAuthenticated,
  selectServerLicenceState,
} from "./selectors";
import { startStoreAndLocationSync } from "./syncStoreAndLocation";
import {
  take,
  call,
  cancel,
  takeLatest,
  all,
  select,
  fork,
  delay,
  put,
  race,
  getContext,
  cancelled,
} from "typed-redux-saga";
import {
  appStartPersistUserAuthentication,
  userAuthenticationWatcherSaga,
  clearAuthentication,
  persistSystem,
  clearSystem,
  clearProvisioningId,
} from "./sagas/authentication";
import { focusedJobContextWatcher } from "./sagas/focusedJob";
import { manageUsersRootSaga } from "./manageUsers/rootSaga";
import { dateModeWatcherSaga } from "./sagas/dateMode";
import { Task } from "redux-saga";
import { getGlobalConfigs } from "@/index";
import { machinePerformanceRootSaga } from "./machinePerformance/rootSaga";
import { processActionsRootSaga } from "./processUpdates/rootSaga";
import { CMSClientType } from "@/cms-api";
import i18n from "@/i18n";

/**
 * The starting point for redux saga in Manatee.
 */
export function* rootSaga() {
  yield* takeLatest(
    getType(rootActions.global.licenceServiceRefreshButtonClicked),
    licenceServiceRefresh
  );
  yield* fork(serverLicenceChecker);
  yield* fork(userAuthenticationWatcherSaga);
  yield* fork(dataLoadingPerformanceNotificationWatcher);
  yield* call(appStartPersistUserAuthentication);
  yield* call(startStoreAndLocationSync);
  yield* fork(userIsAuthenticatedSaga);
}

/**
 * yields when the user has logged out by wrapping known actions
 */
function* whenUserLoggedOut() {
  yield* take([
    getType(rootActions.session.userLoggedOut),
    getType(rootActions.session.headerSignOutLinkClicked),
    getType(rootActions.session.axiosInterceptorTokenCallFailed),
    getType(rootActions.session.axiosInterceptorFailedToRefreshToken),
  ]);
}

/**
 * yields when the server is not licensed by wrapping known actions
 */
function* whenServerIsUnlicensed() {
  yield* take([getType(rootActions.session.serverNotLicensed)]);
}

/**
 * yields when the licence server is not running
 */
function* whenLicenceServiceNotRunning() {
  yield* take([getType(rootActions.session.licenceServiceNotRunning)]);
}

/**
 * calls the system endpoint and catches any server errors
 */
function* checkServerIsLicensed(tries = 0) {
  const maxTries = 10;
  const client: CMSClientType = yield getContext("client");
  try {
    const response = yield* call(() => client.system().promise);
    if (response !== undefined) {
      yield* call(persistSystem, { ...response });
      yield* put(rootActions.session.serverLicenceChecked(response));
      return response.type === "Valid" || (response && !response.type);
    }
    throw "Licence call failed. Retrying.";
  } catch (error) {
    localStorage.removeItem("system");
    localStorage.removeItem("provisioningId");

    // Retry licence call. Slightly odd syntax to avoid type errors with recursion.
    if (tries < maxTries) {
      tries++;
      yield* delay(1000 * tries);
      if (checkServerIsLicensed(tries)) return true;
    }

    return false;
  }
}

/**
 * Watcher that loops and checks the server licensed state.
 */
function* serverLicenceChecker() {
  let licenceServiceNotRunning = "",
    licenceServiceNotRunningRetry = "",
    licenceServiceRunningAndUnlicensed = "",
    licenceServiceRunningAndUnlicensedRetry = "",
    connectionFailedToCentralServer = "",
    connectionFailedToCentralServerRetry = "";

  i18n.then((t) => {
    licenceServiceNotRunning = t(
      "message-theLicenceServiceFailedToConnectToLicenceManager"
    );
    licenceServiceNotRunningRetry = t(
      "message-theLicenceServiceFailedToConnectToLicenceManagerRetry"
    );
    licenceServiceRunningAndUnlicensed = t(
      "message-theLicenceServiceIsRunningButUnlicensed"
    );
    licenceServiceRunningAndUnlicensedRetry = t(
      "message-theLicenceServiceIsRunningButUnlicensedRetry"
    );
    connectionFailedToCentralServer = t(
      "message-connectionFailedToCentralServer"
    );
    connectionFailedToCentralServerRetry = t(
      "message-connectionFailedToCentralServerRetry"
    );
  });
  const config = yield* call(() => getGlobalConfigs());
  while (true) {
    const islicensed = yield* call(checkServerIsLicensed);
    const userIsAuthenticated = yield* select(selectIsAuthenticated);
    if (islicensed) {
      yield* put(rootActions.session.serverIsLicensed());
    }
    const system = JSON.parse(localStorage.getItem("system")!);
    // status can be LicenceServiceNotRunning or sometime LicenceServiceError when egret licence server is not running
    const isLicenceServiceIsNotRunning =
      (system && system.status === "LicenceServiceNotRunning") ||
      (system && system.status === "LicenceServiceError");
    const isUnlicencedServiceRunning = system && system.status === "Unlicensed";
    if (isLicenceServiceIsNotRunning) {
      yield* put(
        rootActions.session.licenceServiceNotRunning({
          message: licenceServiceNotRunning,
          retryMessage: licenceServiceNotRunningRetry,
        })
      );
    }
    if (isUnlicencedServiceRunning) {
      yield* put(
        rootActions.session.unLicencedServiceRunning({
          message: licenceServiceRunningAndUnlicensed,
          retryMessage: licenceServiceRunningAndUnlicensedRetry,
        })
      );
    }
    if (system && system.status === "Valid") {
      yield* put(rootActions.session.serverIsLicensed());
    }
    if (!system && !userIsAuthenticated) {
      yield* put(
        rootActions.session.connectionFailedToCentralServer({
          message: connectionFailedToCentralServer,
          retryMessage: connectionFailedToCentralServerRetry,
        })
      );
    }
    // show login screen when status is null, to be removed when system endppoint is changed
    if (system && !system.status?.length) {
      yield* put(rootActions.session.connectionToServerIsNotDefined());
    }
    yield* delay(config.refreshRateCoreInMS);
  }
}

/**
 * Logged in user flow.
 */
function* userIsAuthenticatedSaga() {
  while (true) {
    try {
      const userIsAuthenticated = yield* select(selectIsAuthenticated);
      const serverLicenceState = yield* select(selectServerLicenceState);
      if (userIsAuthenticated === false || serverLicenceState !== "licensed") {
        yield* delay(100);
        continue;
      }

      yield* call(getCoreDataSaga);
      yield* race({
        cancelUserLoggedOut: call(whenUserLoggedOut),
        cancelServerUnlicensed: call(whenServerIsUnlicensed),
        cancelLicenceServiceNotRunning: call(whenLicenceServiceNotRunning),
        work: call(function* work() {
          let tasks: Task[] = [];
          try {
            /** Main sagas to run when logged in.
             * If one of these fails, it will cause logout, so make sure they handle errors.
             */
            const forkedSagas = [
              fork(coreDataLoopSaga),
              fork(dateModeWatcherSaga),
              fork(focusedJobContextWatcher),
              fork(routingWatcherSaga),
              takeLatest(
                getType(rootActions.global.refreshCoreData),
                getCoreDataSaga
              ),
            ];
            tasks = yield* all(forkedSagas);
          } catch (error) {
            console.error(error);
          } finally {
            if (yield* cancelled()) {
              yield* all(tasks.map((task) => cancel(task)));
            }
          }
        }),
      });

      yield* call(clearAuthentication);
      yield* call(clearSystem);
      yield* call(clearProvisioningId);
    } catch (error) {
      console.warn(`Authenticated flow: ${error}`);
    }
  }
}

/**
 * Watcher for route actions, providing debounce and cancellation.
 */
function* routingWatcherSaga() {
  try {
    const config = yield* call(() => getGlobalConfigs());
    const initialRoute: Routes = yield* select(selectCurrentRoute);
    let task: Task = yield* fork(routingSaga, initialRoute);
    let currentTaskRoute: Routes = initialRoute;
    while (yield* take(getType(rootActions.session.routeChanged))) {
      const newRoute: Routes = yield* select(selectCurrentRoute);
      if (!currentTaskRoute || currentTaskRoute !== newRoute) {
        if (task) {
          yield* cancel(task);
        }
        // Delay in case the user is hopping around routes
        yield* delay(config.userLoaderTimeInMS);
        task = yield* fork(routingSaga, newRoute);
        currentTaskRoute = newRoute;
      }
    }
  } catch (error) {
    console.warn(`Routing watcher: ${error}`);
  }
}

/**
 * Executes a root saga (or sagas) for the current route.
 */
function* routingSaga(route: Routes) {
  switch (route) {
    case "current status": {
      yield* call(currentStatusRootSaga);
      break;
    }
    case "manage users": {
      yield* call(manageUsersRootSaga);
      break;
    }
    case "manage myaccount":
      yield* call(manageMyAccountRootSaga);
      break;
    case "job performance":
      yield* call(jobPerformanceRootSaga);
      break;
    case "manage assets":
      yield* call(manageAssetsRootSaga);
      break;
    case "manage provisioning":
      yield* call(manageDeviceRootSaga);
      break;
    case "machine analysis":
      yield* call(machineAnalysisRootSaga);
      break;
    case "machine performance":
      yield* call(machinePerformanceRootSaga);
      break;
    case "manage clients":
      yield* call(manageClientsRootSaga);
      break;
    case "manage notifications":
      yield* call(manageNotificationsRootSaga);
      break;
    case "manage":
    case "reset password": {
      yield* call((route: Routes) => {
        console.info("[ROUTE SAGA]", "root saga", route);
      }, route);
      break;
    }
    case "process updates": {
      yield* call(processActionsRootSaga);
      break;
    }
    default: {
      yield* call(assertUnreachable, route);
    }
  }
}

function* licenceServiceRefresh() {
  try {
    let licenceServiceNotRunning = "",
      licenceServiceNotRunningRetry = "",
      licenceServiceRunningAndUnlicensed = "",
      licenceServiceRunningAndUnlicensedRetry = "";
    i18n.then((t) => {
      licenceServiceNotRunning = t(
        "message-theLicenceServiceFailedToConnectToLicenceManager"
      );
      licenceServiceNotRunningRetry = t(
        "message-theLicenceServiceFailedToConnectToLicenceManagerRetry"
      );
      licenceServiceRunningAndUnlicensed = t(
        "message-theLicenceServiceIsRunningButUnlicensed"
      );
      licenceServiceRunningAndUnlicensedRetry = t(
        "message-theLicenceServiceIsRunningButUnlicensedRetry"
      );
    });
    const client = yield* getContext<CMSClientType>("client");
    const response = yield* call(() => client.system().promise);
    // status can be LicenceServiceNotRunning or sometime LicenceServiceError when egret licence server is not running
    const isLicenceServiceIsNotRunning =
      (response && response.status === "LicenceServiceNotRunning") ||
      (response && response.status === "LicenceServiceError");
    const isUnlicencedServiceRunning =
      response && response.status === "Unlicensed";
    if (isLicenceServiceIsNotRunning) {
      yield* put(
        rootActions.session.licenceServiceNotRunning({
          message: licenceServiceNotRunning,
          retryMessage: licenceServiceNotRunningRetry,
        })
      );
      clearAuthentication();
    } else if (isUnlicencedServiceRunning) {
      yield* put(
        rootActions.session.unLicencedServiceRunning({
          message: licenceServiceRunningAndUnlicensed,
          retryMessage: licenceServiceRunningAndUnlicensedRetry,
        })
      );
      clearAuthentication();
    } else {
      if (response !== undefined && response.status === "Valid") {
        yield* call(persistSystem, { ...response });
        yield* put(rootActions.session.serverIsLicensed());
      }
    }
  } catch (e) {
    console.log(e);
  }
}
